import React, { Component } from 'react';
import TeamMemberItem from './TeamMemberItem';
import AndrewImg from './../../../assets/people/Andrew_Brook.jpg';
import CarlImg from './../../../assets/people/Carl_Fletcher.jpg';
import JonImg from './../../../assets/people/Jon_Ashby.jpg';
import PeterSImg from './../../../assets/people/Peter_Stephenson.jpg';
import SteinImg from './../../../assets/people/Stein_Fletcher.jpg';
import WilliamImg from './../../../assets/people/William_Hayes.jpg';
import SeanImg from './../../../assets/people/Sean_Sweeney.jpg';
import RichardImg from './../../../assets/people/Richard_Flanagan.jpg';

class TeamSection extends Component {
    render() {
        return (
            <section className="medium-padding120 body-bg-white">
                <div className="container">
                    <div className="row mb60">
                        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 m-auto">
                            <div className="crumina-module crumina-heading align-center">
                                <h2 className="heading-title">Meet our Team</h2>
                                <p className="heading-text">Stallions. Each one more magnificent than the last.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row teammembers-wrap">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                            <TeamMemberItem name="Peter Stephenson" position="Founder & CEO" picture={PeterSImg} />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                            <TeamMemberItem name="Stein Fletcher" position="CTO" picture={SteinImg} />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                            <TeamMemberItem name="Andrew Brook" position="Senior Developer" picture={AndrewImg} />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                            <TeamMemberItem name="Richard Flanagan" position="iOS Developer" picture={RichardImg} />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                            <TeamMemberItem name="Carl Fletcher" position="Android Developer" picture={CarlImg} />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                            <TeamMemberItem name="Jon Ashby" position="Senior Designer" picture={JonImg} />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                            <TeamMemberItem name="William Hayes" position="Legal & Commercial" picture={WilliamImg} />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                            <TeamMemberItem name="Sean Sweeney" position="Videography" picture={SeanImg} />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default TeamSection;
