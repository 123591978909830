import React, { Component } from 'react'
import { DEFAULTS } from '../config'

class OpenShortHalfLeft extends Component {


    render() {
        return (
            <svg className="halfNoteDefault" viewBox="0 0 5 10">
                <path d="M0,8c1.5,0,2.54,2,5,2V9.5C2.53,9.5,1.5,7.75,0,7.75Z"/>
                <path d="M0,2v.24C1.5,2.24,2.53.5,5,.5V0C2.53,0,1.49,2,0,2Z"/>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSize} x={DEFAULTS.textXLeftPosition}
                      y={DEFAULTS.textYPosition}>{this.props.note}</text>

            </svg>
        )
    }
}

export default OpenShortHalfLeft
