import React, { Component } from 'react'
import { DEFAULTS } from '../config'

class QuarterRight extends Component {

    render() {
        return (
            <svg className="halfNoteDefault" viewBox="0 0 5 10">
                <path
                    d="M0,0H0V.38A4.63,4.63,0,0,1,1.52.65L0,2V8L1.52,9.35A4.36,4.36,0,0,1,0,9.63V10H0A5,5,0,0,0,5,5,5,5,0,0,0,0,0ZM1.87,9.22.12,7.75V2.25L1.86.79A4.74,4.74,0,0,1,4.75,5,4.78,4.78,0,0,1,1.87,9.22Z"/>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSize} x="2.16"
                      y={DEFAULTS.textYPosition}>{this.props.note}</text>
            </svg>
        )
    }
}

export default QuarterRight
