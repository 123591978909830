import React, { Component } from 'react'
import { DEFAULTS } from '../config'

class SustainClose extends Component {

    render() {
        return (
            <svg className="noteDefault" viewBox="0 0 10 10">
                <path
                    d="M5,10H5A5,5,0,0,0,5,0H5C2.54,0,1.5,2,0,2v.25C1.5,2.25,2.54.5,5,.5H5A4.72,4.72,0,0,1,9.75,5,4.73,4.73,0,0,1,5,9.5H5c-2.46,0-3.5-1.75-5-1.75V8c1.5,0,2.54,2,5,2Z"/>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSize} x={DEFAULTS.textXMiddlePosition}
                      y={DEFAULTS.textYPosition}>{this.props.note}</text>
            </svg>
        )
    }
}

export default SustainClose
