import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import capitalize from "lodash/capitalize";
import { browserHistory } from "react-router";

const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
];

const defaultColor = "grey";

class AlphabetHeader extends Component {
    state = {
        selectedInitial: "A",
    };

    componentWillReceiveProps(props) {
        let initial = props.initial;
        if (initial) {
            this.setState({
                selectedInitial: initial,
            });
        }
    }

    handleLetterClick(e) {
        let letter = e.target.value;
        this.setState({ selectedInitial: letter });
        this.props.fetchByInitial(letter);
        this.updateBrowserHistory(letter);
    }

    updateBrowserHistory(initial) {
        let genre = this.props.genre;
        let genreUrl = "";
        if (genre) {
            genre = this.props.genre
                .split("_")
                .map((e) => capitalize(e))
                .join(" ");
            genreUrl = "genre=" + genre + "&";
        }
        browserHistory.push(`/browse/list?${genreUrl}initial=${initial}`);
    }

    renderAlphabetMenu() {
        const alphabetMenu = alphabet.map((letter, i) => {
            return (
                <Button
                    size="mini"
                    value={letter}
                    onClick={this.handleLetterClick.bind(this)}
                    circular
                    color={defaultColor}
                    basic
                    key={i}
                >
                    {letter}
                </Button>
            );
        });
        return <div>{alphabetMenu}</div>;
    }

    render() {
        return <div>{this.renderAlphabetMenu()}</div>;
    }
}

export default AlphabetHeader;
