import axios from "axios";
import { removeSession } from "./sessionStorage";
import defaults from "lodash/defaults";

export const LoadingState = {
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
    PENDING: "PENDING",
    UNKNOWN: "UNKNOWN",
};

export const Error = {
    REQUEST_PARAMETER_VALIDATION: "REQUEST_PARAMETER_VALIDATION",
    ENTITY_EXISTS: "ENTITY_EXISTS",
    USERNAME_ALREADY_USED: "USERNAME_ALREADY_USED",
    EMAIL_ALREADY_USED: "EMAIL_ALREADY_USED",
    USER_ALREADY_REGISTERED: "USER_ALREADY_REGISTERED",
    EMAIL_NOT_WHITELISTED: "EMAIL_NOT_WHITELISTED",
};

export const containsError = (response, message) => {
    return response && response.code === message;
};

export const mtudeApi = (path) => `/mtude-api${path}`;

export const Method = {
    GET: "GET",
    POST: "POST",
    DELETE: "DELETE",
    PUT: "PUT",
};

export const MediaType = {
    JSON: "application/json",
};

export const Header = {
    CONTENT_TYPE: "Content-Type",
    ACCEPT: "Accept",
};

export const Http = axios.create();

/**
 * Http client
 */
export class HttpClient {
    constructor(options) {
        this.options = defaults(options, {
            host: "",
            headers: {},
        });
    }

    /**
     * Makes a http request using axios http client
     * @param {string} method - the http verb
     * @param {string} path - the path
     * @param {object?} data - the request body
     * @returns {*}
     */
    request = (method, path, data) => {
        const defaultHeaders = {
            [Header.ACCEPT]: MediaType.JSON,
            [Header.CONTENT_TYPE]: MediaType.JSON,
        };

        return Http({
            url: path,
            baseURL: this.options.host,
            method: method,
            headers: Object.assign({}, defaultHeaders, this.options.headers),
            data: data,
            credentials: "same-origin",
        });
    };
}

Http.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error && error.response) {
            if (error.response.status === 503) {
                window.location = "/no-service";
            } else if (
                error.response.status === 401 ||
                error.response.status === 403
            ) {
                removeSession();
            }
        }
        return Promise.reject(error);
    }
);

export const getURLParam = (name) => {
    let match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
};
