import React, { Component } from 'react'

class OpenCradleRight extends Component {

    render() {
        return (
            <svg className="cradleDefault" viewBox="0 0 19.92 5.76">
                <path d="M19.92,0.48V0h-1.575c-0.813,0-3.969,5.037-4.609,5.037H0v0.48c0,0,13.205,0,13.892,0s3.828-5.053,4.672-5.053
	            S19.92,0.48,19.92,0.48z"/>
            </svg>
        )
    }
}

export default OpenCradleRight
