import React, { Component } from 'react'
import MusitudeFullLogo from '../../../assets/Musitude-Logo-RGB-Strap-R-compressor.jpg'
import { Icon, Image } from 'semantic-ui-react'

class Footer extends Component {
    render() {
        return (
            <div className="bootstrap-styles">
                <div className="olympus-styles">
                    <div className="footer footer-full-width" id="footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="widget w-about">
                                        <Image style={{
                                            padding: '1em',
                                            width: '230px',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        }} src={MusitudeFullLogo}/>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="widget w-list">
                                        <h6 className="title">Main Links</h6>
                                        <ul>
                                            <li>
                                                <a href="/">Home</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="widget w-list">
                                        <h6 className="title">Musitude</h6>
                                        <ul>
                                            <li>
                                                <a href="/terms">Terms of Use</a>
                                            </li>
                                            <li>
                                                <a href="/privacy">Privacy</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="widget w-list">
                                        <h6 className="title">Contact</h6>
                                        <ul>
                                            <li>
                                                <a href="mailTo:info@musitude.com">info@musitude.com</a>
                                            </li>
                                            <li>
                                                <a href="mailTo:support@musitude.com">support@musitude.com</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <ul className="socials" style={{
                                        paddingTop: '25px',
                                        minWidth: '270px',
                                        textAlign: 'center'
                                    }}>
                                        <li>
                                            <a href="https://www.facebook.com/musitude/">
                                                <Icon fitted name='facebook' className='social-icons'/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/musitude">
                                                <Icon fitted name='twitter' className='social-icons'/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/channel/UCB7YzbTmOcjLCinMgCm917g">
                                                <Icon fitted name='youtube' className='social-icons'/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.twitch.tv/musitude">
                                                <Icon fitted name='twitch' className='social-icons'/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/musitudeapp/">
                                                <Icon fitted name='instagram' className='social-icons'/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/musitude/">
                                                <Icon fitted name='linkedin' className='social-icons'/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://discord.com/invite/FpAnyTc">
                                                <Icon fitted name='discord' className='social-icons'/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="sub-footer-copyright" style={{ marginTop: '0' }}>
                                        <span>
                                           &copy; 2014-2020 Musitude Ltd
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer
