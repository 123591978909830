import React, { Component } from 'react'

class RestHalfLeft extends Component {


    render() {
        return (
            <svg className="halfNoteDefault restNote" viewBox="0 0 5 10">
                <path d="M4.26.56V.06A5,5,0,0,0,1.1,1.88l.44.25A4.49,4.49,0,0,1,4.26.56Z"/>
                <path d="M1.54,7.86l-.44.26A5,5,0,0,0,4.26,9.93v-.5A4.44,4.44,0,0,1,1.54,7.86Z"/>
                <path
                    d="M.51,5A4.51,4.51,0,0,1,.79,3.43L.36,3.18A4.76,4.76,0,0,0,0,5,4.83,4.83,0,0,0,.36,6.82l.43-.26A4.45,4.45,0,0,1,.51,5Z"/>
                <rect x="4.76" width="0.25" height="10"/>
            </svg>
        )
    }
}

export default RestHalfLeft
