import React from "react";
import musitudeLogo from "../../assets/Musitude-Logo-white.png";
import isoLogo from "../../assets/ios-badge.svg";

import { Image } from "semantic-ui-react";

function AndroidView() {
    return (
        <div
            className="app-landing row"
            style={{
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
            }}
        >
            <div>
                <Image
                    src={musitudeLogo}
                    size="medium"
                    centered
                    style={{ paddingBottom: "100px" }}
                />
                <h3>Please use Musitude on a PC/Laptop</h3>
                <h4> Or </h4>
                <h3>Download the App available now!</h3>
                <a href="https://itunes.apple.com/us/app/musitude/id1409620925?ls=1&mt=8">
                    <Image
                        src={isoLogo}
                        size="small"
                        centered
                        style={{ paddingTop: "20px" }}
                    />
                </a>
            </div>
        </div>
    );
}

export default AndroidView;
