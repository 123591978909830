import React from "react";
import firefoxIcon from "../../assets/icons/landing/firefox-logo-300x310.png";
import chromeIcon from "../../assets/icons/landing/Chrome-icon.png";
import { Image } from "semantic-ui-react";
import musitudeLogo from "../../assets/Musitude-Logo-white.png";

function UnsupportedBrowserView() {
    return (
        <div className="bootstrap-styles">
            <div className="ie-landing">
                <div className="row">
                    <div className="col-md-11 offset-md-1 col-xs-11 offset-xs-1">
                        <Image
                            src={musitudeLogo}
                            size="medium"
                            style={{ paddingBottom: "50px" }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="offset-md-3 offset-sm-2 col-md-7 col-sm-8 col-xs-10">
                        <h2>
                            Oops! Internet Explorer doesn't support features
                            needed for the Musitude experience!
                        </h2>
                        <br />

                        <h4>
                            Please consider using an alternative browser, we
                            recommend:
                        </h4>

                        <div className="row">
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <a
                                    href="https://www.mozilla.org/en-US/firefox/new/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Image src={firefoxIcon} size="tiny" />
                                </a>
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                <div style={{ paddingTop: "15px" }}>
                                    <h4> Mozilla Firefox </h4>
                                    <a
                                        href="https://www.mozilla.org/en-US/firefox/new/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <h5 className="browserLinks">
                                            {" "}
                                            Download Firefox from the official
                                            site
                                        </h5>
                                        <h5 className="browserLinks">
                                            https://www.mozilla.org/en-US/firefox/new
                                        </h5>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ paddingTop: "15px" }}>
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <a
                                    href="https://www.google.com/chrome/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Image src={chromeIcon} size="tiny" />
                                </a>
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                <div style={{ paddingTop: "15px" }}>
                                    <a
                                        href="https://www.google.com/chrome/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <h4> Google Chrome </h4>
                                        <h5 className="browserLinks">
                                            {" "}
                                            Download Chrome from the official
                                            site
                                        </h5>
                                        <h5 className="browserLinks">
                                            https://www.google.com/chrome
                                        </h5>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UnsupportedBrowserView;
