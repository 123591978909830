import cookie from 'react-cookie'

const key = 'MtudeSession'

const loadSession = () => cookie.load(key)

export const removeSession = () => {
    cookie.remove(key)
}

export const hasSession = () => {
    return loadSession() !== undefined
}

export const getSession = () => {
    const rawCookie = loadSession()
    if (rawCookie) {
        const cookieComponents = rawCookie.match(/[^.]*\.([^.]+)\..*/)
        if (cookieComponents && cookieComponents.length >= 1) {
            const base64Session = cookieComponents[1]
            try {
                return JSON.parse(atob(base64Session))
            } catch (e) {
                console.error('Failed to parse session cookie')
            }
        }
    }
    return undefined
}
