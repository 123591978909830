import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactImageFallback from "react-image-fallback";
import { browserHistory } from "react-router";
import fallbackImg from "./../../../styles/olympus/app/img/avatar41-sm.jpg";

class ListItems extends Component {
    static propTypes = {
        items: PropTypes.arrayOf(
            PropTypes.shape({
                imageHref: PropTypes.string,
                title: PropTypes.string,
                titleLink: PropTypes.string,
                subTitle: PropTypes.string,
                subTitleLink: PropTypes.string,
            })
        ),
    };

    handleSelect(item) {
        this.props.onSelect(item);
    }

    render() {
        const items = this.props.items.map((a, i) => {
            return (
                <li
                    key={i}
                    className="inline-items ubuntuFont pt-2 pb-2"
                    onClick={() => browserHistory.push(a.titleLink)}
                >
                    <div className="playlist-thumb">
                        <ReactImageFallback
                            src={a.imageHref}
                            fallbackImage={fallbackImg}
                            alt="imageItem"
                            style={{
                                height: "34px",
                                width: "34px",
                            }}
                        />
                    </div>
                    <div className="composition">
                        <span
                            onClick={() => browserHistory.push(a.titleLink)}
                            className="guide-text composition-name"
                        >
                            {a.title}
                        </span>
                        <span
                            onClick={() => browserHistory.push(a.subTitleLink)}
                            className="guide-text composition-author"
                        >
                            {a.subTitle}
                        </span>
                    </div>
                </li>
            );
        });
        return (
            <div className="bootstrap-styles">
                <div className="olympus-styles">
                    <ul className="widget w-playlist">{items}</ul>
                </div>
            </div>
        );
    }
}

export default ListItems;
