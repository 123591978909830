import React, { Component } from 'react'
import { DEFAULTS } from '../config'

class Triplet extends Component {
    render() {
        return (
            <svg className="noteDefault" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                <g>
                    <path d="M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0ZM.63,5A4.65,4.65,0,0,1,3.38.71V9.29A4.63,4.63,0,0,1,.63,5Zm3,4.37V.63A4.11,4.11,0,0,1,5,.38,4.45,4.45,0,0,1,6.62.7V9.31A4.45,4.45,0,0,1,5,9.63,3.88,3.88,0,0,1,3.63,9.37Zm3.24-.16V.8A4.73,4.73,0,0,1,9.75,5,4.76,4.76,0,0,1,6.87,9.21Z"/>
                </g>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSizeTriplet} x="2.1" y="6">{this.props.note1}</text>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSizeTriplet} x="5.1" y="6">{this.props.note2}</text>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSizeTriplet} x="8.1" y="6">{this.props.note3}</text>
            </svg>
        )
    }
}

export default Triplet
