import React from "react";
import { Card } from "../../components/Card/Card.component";
import OlympusListItems from "../../components/olympus/components/ListItems";
import { gql, useQuery } from "@apollo/client";

const query = gql`
    query {
        notation(limit: 6, offset: 0, sortBy: ADDED_DESC) {
            id
            title
            artist {
                name
                id
                imageHref
            }
        }
    }
`;

const RecentlyAdded = () => {
    const { data, loading, error } = useQuery(query);

    if (loading || error) {
        return null;
    }

    return (
        <Card title="Recently Added">
            <OlympusListItems
                items={data.notation.map((e) =>
                    Object.assign({}, e, {
                        imageHref: e.artist.imageHref,
                        title: e.title,
                        titleLink: `/player/${e.id}`,
                        subTitle: e.artist.name,
                        subTitleLink: `/browse/list?artist=${e.artist.id}`,
                    })
                )}
            />
        </Card>
    );
};

export default RecentlyAdded;
