import React from "react";
import Slider from "react-slick";
import OpenCradleLeft from "./../notation/blocks/cradle/OpenCradleLeft";
import OpenCradleRight from "./../notation/blocks/cradle/OpenCradleRight";
import OpenCradleMiddle from "./../notation/blocks/cradle/OpenCradleMiddle";
import OpenCradleJoiner from "./../notation/blocks/cradle/OpenCradleJoiner";
import each from "lodash/each";
import { DynamicNote } from "./dynamicnote.component";

const sliderSettings = {
    dots: true,
    infinite: false,
    focusOnSelect: false,
    fade: false,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
};
const BASE_10 = 10;

const JOINER_WIDTH = 5.25;
const NOTE_WIDTH = 35;
const CRADLE_HEIGHT = 98;

export const NotationRenderer = React.forwardRef((props, ref) => {
    const { variation, height, width } = props;
    let signature = parseInt(variation.signature, BASE_10);
    const pages = paginateCradles(variation.cradles, height, width, signature);

    return (
        <div style={{ height: height, width: width }}>
            <Slider ref={ref} {...sliderSettings}>
                {pages
                    ? pages.map((cradles, i) => (
                          <Slide
                              key={i}
                              cradles={cradles}
                              signature={signature}
                              count={i}
                          />
                      ))
                    : ""}
            </Slider>
        </div>
    );
});

/**
 * Divide the variation's cradles into pages according to the client window
 * @param cradles
 * @param height
 * @param width
 * @param signature
 * @returns {Array}
 */
const paginateCradles = (cradles, height, width, signature) => {
    //container size
    const cradleWidth = getCradleWidth(signature);
    const numberOfRows = Math.floor(height / CRADLE_HEIGHT);
    const numberOfCradlesPerRow = Math.floor(width / cradleWidth);
    const maxPerPage = numberOfRows * numberOfCradlesPerRow;

    let pagedNotation = [];
    let pageArray = [];
    let counter = 0;
    each(cradles, (item, i) => {
        if (counter < maxPerPage) {
            pageArray.push(item);
        } else {
            pagedNotation.push(pageArray);
            pageArray = [item];
            counter = 0;
        }
        counter++;
    });
    //add final page
    pagedNotation.push(pageArray);

    return pagedNotation;
};

const getCradleWidth = (signature) => {
    return signature * (NOTE_WIDTH + JOINER_WIDTH);
};

const Slide = (props) => {
    const { cradles, signature, count } = props;
    return (
        <div key={`slide-key${count}`}>
            {cradles &&
                cradles.map((cradle, i) => (
                    <span
                        key={i}
                        style={{ marginRight: 0, display: "inline-block" }}
                    >
                        <div className="trackingLine"> </div>
                        <div style={{ fontFamily: "Harmattan,sans-serif" }}>
                            <CradleNotes
                                key={`notes${i}`}
                                pieces={cradle.pieces}
                                signature={signature}
                            />
                        </div>
                        <div style={{ marginTop: -35 }}>
                            <CradleFooter key={i} signature={signature} />
                        </div>
                    </span>
                ))}
        </div>
    );
};

const CradleNotes = (props) => {
    let cradlePosition = 0;
    return (
        <span>
            {props.pieces &&
                props.pieces.map((piece, i) => {
                    const Note = (
                        <DynamicNote
                            key={i}
                            piece={piece}
                            cradlePosition={cradlePosition}
                            signature={props.signature}
                        />
                    );
                    cradlePosition += piece.duration;
                    return Note;
                })}
        </span>
    );
};

const CradleFooter = (props) => {
    return (
        <span className="cradle">
            <OpenCradleLeft />
            <OpenCradleJoiner />
            {[...Array(props.signature - 2)].map((x, i) => (
                <span key={i}>
                    <OpenCradleMiddle />
                    <OpenCradleJoiner />
                </span>
            ))}
            <OpenCradleRight />
        </span>
    );
};
