import React from "react";
import { Image } from "semantic-ui-react";
import musitudeLogo from "../../assets/Musitude-Logo-white.png";

function AndroidView() {
    return (
        <div
            className="app-landing row"
            style={{
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
            }}
        >
            <div>
                <Image
                    src={musitudeLogo}
                    size="medium"
                    centered
                    style={{ paddingBottom: "60px" }}
                />

                <h3>Please use Musitude on a PC/Laptop</h3>
                <h4> Or </h4>
                <h3>Download the App available now!</h3>
                <a href="https://play.google.com/store/apps/details?id=rocks.musitude.androidapp&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <Image
                        alt="Get it on Google Play"
                        centered
                        src="https://play.google.com/intl/en_gb/badges/images/generic/en_badge_web_generic.png"
                        size="medium"
                        style={{ paddingTop: "20px" }}
                    />
                </a>
            </div>
        </div>
    );
}

export default AndroidView;
