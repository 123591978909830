import React, { Component } from 'react'

class OpenCradleJoiner extends Component {

    render() {
        return (
            <svg
                className="cradleJoinerDefault"
                viewBox="0 0 2.99 5.76">
                <path
                    d="M 31.7325,0.48 V 0 H 29.22353 C 27.928423,0 22.900925,5.037 21.881407,5.037 H 0 v 0.48 c 0,0 21.035525,0 22.129914,0 1.094389,0 6.097993,-5.053 7.442482,-5.053 1.34449,0 2.160104,0.016 2.160104,0.016 z"
                    id="path2"/>
            </svg>
        )
    }
}

export default OpenCradleJoiner
