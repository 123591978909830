import React, { Component } from 'react'
import { DEFAULTS } from '../config'

class OpenHalfRight extends Component {


    render() {
        return (
            <svg className="halfNoteDefault" viewBox="0 0 5 10">
                <path d="M0,.5H0A4.72,4.72,0,0,1,4.76,5,4.73,4.73,0,0,1,0,9.5H0V10H0A5,5,0,0,0,0,0H0Z"/>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSize} x={DEFAULTS.textXRightPosition}
                      y={DEFAULTS.textYPosition}>{this.props.note}</text>
            </svg>
        )
    }
}

export default OpenHalfRight
