import React, { Component } from 'react'
import { Image } from 'semantic-ui-react'

class TeamMemberItem extends Component {
    render() {
        return (
            <div className="bootstrap-styles">
                <div className="olympus-styles">
                    <div className="crumina-module crumina-teammembers-item">
                        <div>
                            <Image size='small' className="circular centered" src={this.props.picture}
                                   alt="team member"/>
                        </div>
                        <div className="teammember-content">
                            <h5 className="teammembers-item-name">
                                {this.props.name}
                            </h5>
                            <div style={{ fontSize: '14px' }}>
                                {this.props.position}
                            </div>
                            <div className="teammembers-item-prof">  {this.props.description} </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TeamMemberItem
