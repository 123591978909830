import React, { Component } from "react";
import { Checkbox, Grid, Icon, Image, Menu, Popup } from "semantic-ui-react";
import filter from "lodash/filter";
import isUndefined from "lodash/isUndefined";
import PlayIcon from "../../assets/icons/player/play.svg";
import StopIcon from "../../assets/icons/player/stop.svg";
import { Event } from "../../services/Event";

class AutoplayerButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            variations: props.variations,
            variation: props.variation,
            soundplayer: props.soundplayer,
            playbackIsOn: false,
            withDesignatedInstrument: true,
        };

        this.stop = this.props.bus.take(Event.PlaybackFinished, () => {
            // this.stopPlayback(); // FIXME disabled temporarily
        });
    }

    componentWillUnmount() {
        this.stop();
    }

    componentWillReceiveProps(props) {
        //if variation changes, stop autoplayer
        if (
            this.state.variation.id !== props.variation.id &&
            this.state.playbackIsOn
        ) {
            this.stopPlayback();
            this.setState({ playbackIsOn: false });
        }

        this.setState({
            variations: props.variations,
            variation: props.variation,
            soundplayer: props.soundplayer,
        });
    }

    designatedInstrumentCheckboxChanged(e, data) {
        this.setState({ withDesignatedInstrument: data.checked });
    }

    togglePlayTracker(event, data) {
        this.props.playTrackerChanged(this.props.playTrackerActive);
    }

    showVariationAutoplayPopup(variations, selectedVariation) {
        const withDesignatedInstrument = this.state.withDesignatedInstrument;
        const variationsWithoutClip = filter(variations, (item) => {
            return item.type.value !== "CLIP";
        });
        const showAccompaniments = variationsWithoutClip.length > 2;
        const accompaniments = filter(variationsWithoutClip, (item) => {
            return item.id !== selectedVariation.id;
        });
        return (
            <Popup
                style={{
                    height:
                        35 * variations.length +
                        (showAccompaniments ? 105 : 85),
                }}
                trigger={
                    <Menu.Item>
                        <Icon size="big">
                            {" "}
                            <Image centered src={PlayIcon} />{" "}
                        </Icon>
                        <span className="mobile-hide mtudeIconButton iconFont">
                            Play
                        </span>
                    </Menu.Item>
                }
                on="click"
                position="bottom left"
            >
                {variationsWithoutClip.length > 1 ? (
                    <Grid.Row>
                        <Grid.Column>
                            <span
                                className="mtudeIconButton"
                                onClick={() =>
                                    this.startPlayback(
                                        variationsWithoutClip,
                                        withDesignatedInstrument
                                    )
                                }
                            >
                                <Icon size="large">
                                    {" "}
                                    <Image centered src={PlayIcon} />{" "}
                                </Icon>{" "}
                                Play All
                            </span>
                        </Grid.Column>
                    </Grid.Row>
                ) : (
                    ""
                )}

                {variations.map((variation, index) => {
                    return (
                        <Grid.Row key={index}>
                            <Grid.Column>
                                <span
                                    className="mtudeIconButton"
                                    onClick={() =>
                                        this.startPlayback(
                                            [variations[index]],
                                            withDesignatedInstrument
                                        )
                                    }
                                >
                                    <Icon size="large">
                                        {" "}
                                        <Image centered src={PlayIcon} />{" "}
                                    </Icon>{" "}
                                    {variation.type.displayValue}
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                    );
                })}
                {showAccompaniments ? (
                    <Grid.Row>
                        <Grid.Column>
                            <span
                                className="mtudeIconButton"
                                onClick={() =>
                                    this.startPlayback(
                                        accompaniments,
                                        withDesignatedInstrument
                                    )
                                }
                            >
                                <Icon size="large">
                                    {" "}
                                    <Image centered src={PlayIcon} />{" "}
                                </Icon>{" "}
                                Play Accompaniments
                            </span>
                        </Grid.Column>
                    </Grid.Row>
                ) : (
                    ""
                )}
                <br />
                <Grid.Row>
                    <Grid.Column>
                        <Checkbox
                            onChange={this.togglePlayTracker.bind(this)}
                            checked={this.props.playTrackerActive}
                            label="Tracker on/off"
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Checkbox
                            onChange={this.designatedInstrumentCheckboxChanged.bind(
                                this
                            )}
                            checked={this.state.withDesignatedInstrument}
                            label="Use designated instruments"
                        />
                    </Grid.Column>
                </Grid.Row>
            </Popup>
        );
    }

    startPlayback(variations, withDesignatedInstrument) {
        this.setState({ playbackIsOn: true });
        this.state.soundplayer.startPlayback(
            variations,
            withDesignatedInstrument
        );
    }

    stopPlayback() {
        this.setState({ playbackIsOn: false });
        this.state.soundplayer.stopPlayback();
    }

    render() {
        const { variations, playbackIsOn, variation } = this.state;
        if (isUndefined(variation)) {
            return <div />;
        }
        return (
            <div>
                {playbackIsOn ? (
                    <Menu.Item onClick={this.stopPlayback.bind(this)}>
                        <Icon size="big">
                            {" "}
                            <Image centered src={StopIcon} />{" "}
                        </Icon>
                        <span className="mobile-hide mtudeIconButton iconFont">
                            Stop
                        </span>
                    </Menu.Item>
                ) : variations.length > 1 ? (
                    this.showVariationAutoplayPopup(variations, variation)
                ) : (
                    <Menu.Item
                        onClick={() => this.startPlayback(variations, false)}
                    >
                        <Icon size="big">
                            {" "}
                            <Image centered src={PlayIcon} />{" "}
                        </Icon>
                        <span className="mobile-hide mtudeIconButton iconFont">
                            Play
                        </span>
                    </Menu.Item>
                )}
            </div>
        );
    }
}

export default AutoplayerButton;
