import React, { Component } from 'react'
import ShortHalfLeft from './ShortHalfLeft'
import Joiner from './Joiner'
import OpenLeft from './OpenLeft'
import SustainClose from './SustainClose'
import Middle from './Middle'
import { ShapeDuration } from '../config'

class Sustain extends Component {

    /**
     * Build the middle for sustain shape.
     * The middle is calculated as the duration minus the beats rendered by start and end blocks.
     * The start is and 'OpenLeft' of 2 beats
     * The end for EVEN durations is an 'OpenRight' of 2 beats.
     * The end for ODD durations is an 'OpenShortHalfLeft' of 1 beat.
     * @param duration
     * @returns {Array}
     */

    buildSustainMiddle(duration) {
        const BEATS_PER_MIDDLE = ShapeDuration.MIDDLE
        const ODD_BEATS_START_END = ShapeDuration.OPEN_LEFT + ShapeDuration.SHORT_HALF_LEFT
        const EVEN_BEATS_START_END = ShapeDuration.OPEN_LEFT + ShapeDuration.SUSTAIN_CLOSE

        let middle = []
        let nonMiddleDuration = (this.isOdd(duration) ? ODD_BEATS_START_END : EVEN_BEATS_START_END)
        let noOfMiddleNotes = Math.round((duration - nonMiddleDuration) / BEATS_PER_MIDDLE)

        for (let i = 0; i < noOfMiddleNotes; i++) {
            middle.push(<Joiner key={'j-' + i.toString()}/>)
            middle.push(<Middle key={'m-' + i.toString()}/>)
        }
        return middle
    }

    buildSustainEnd(duration) {
        let end = []
        if (this.isOdd(duration)) {
            end.push(<Joiner key='j-end'/>)
            end.push(<ShortHalfLeft key='oshl-ends'/>)
        } else {
            end.push(<Joiner key='j-end'/>)
            end.push(<SustainClose key='ohr-end'/>)
        }
        return end
    }

    isOdd(num) {
        return num % 2
    }

    render() {
        return (
            <span>
                <OpenLeft note={this.props.note}/>
                {this.buildSustainMiddle(this.props.duration)}
                {this.buildSustainEnd(this.props.duration)}
            </span>
        )
    }
}

export default Sustain
