import React, { Component } from 'react'
import { DEFAULTS } from '../config'

class ShortHalfLeft extends Component {


    render() {
        return (
            <svg className="halfNoteDefault" viewBox="0 0 5 10">
                <path
                    d="M0,2v.24C1.45,2.24,2.46.62,4.74.51v9C2.46,9.37,1.45,7.75,0,7.75V8c1.5,0,2.54,2,5,2V0C2.53,0,1.49,2,0,2Z"/>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSize} x={DEFAULTS.textXLeftPosition}
                      y={DEFAULTS.textYPosition}>{this.props.note}</text>

            </svg>
        )
    }
}

export default ShortHalfLeft
