import React, { Component } from 'react'
class RestHalfRight extends Component {
    render() {
        return (
            <svg  className="halfNoteDefault restNote" viewBox="0 0 5 10">
                <path d="M3.57,2.07l.35-.19A5,5,0,0,0,.76.06v.5A5,5,0,0,1,3.57,2.07Z"/>
                <path
                    d="M4.76,5A4.11,4.11,0,0,1,4.4,6.67l.27.15A5,5,0,0,0,5,5a5,5,0,0,0-.34-1.82l-.27.15A4.07,4.07,0,0,1,4.76,5Z"/>
                <path d="M.76,9.43v.5A5,5,0,0,0,3.92,8.12l-.35-.2A4.89,4.89,0,0,1,.76,9.43Z"/>
                <rect x="0.01" width="0.25" height="10"/>
            </svg>
        )
    }
}

export default RestHalfRight
