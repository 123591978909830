import React, { Component } from 'react'
import { DEFAULTS } from '../config'

class Whole extends Component {

    render() {
        return (
            <svg className="noteDefault" viewBox="0 0 10 10">
                <path
                    d="M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Zm0,9.5a4.5,4.5,0,0,1,0-9A4.73,4.73,0,0,1,9.74,5,4.72,4.72,0,0,1,5,9.49Z"/>

                <text textAnchor="middle" fontSize={DEFAULTS.fontSize} x={DEFAULTS.textXMiddlePosition}
                      y={DEFAULTS.textYPosition}>{this.props.note}</text>

            </svg>
        )
    }
}

export default Whole
