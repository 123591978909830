import startCase from "lodash/startCase";
import isEmpty from "lodash/isEmpty";
import toLower from "lodash/toLower";

/**
 * Orders an object by keys
 */
export const ordered = (object) => {
    const ordered = {};
    Object.keys(object)
        .sort()
        .forEach(function (key) {
            ordered[key] = object[key];
        });
    return ordered;
};

export const titleCase = (input) => {
    if (isEmpty(input)) {
        return input;
    }
    return startCase(toLower(input.replace("_", " ")));
};

export const isValidEmail = (email) => {
    return /^[a-z0-9\u007F-\uffff!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9\u007F-\uffff!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z]{2,}$/i.test(
        email
    );
};

export const defaultDateFormat = (date) => {
    const createDate = new Date(date);
    let dateString;
    const minute = createDate.getMinutes();
    const hour = createDate.getHours();
    const day = createDate.getDate();
    const month = createDate.getMonth() + 1;
    const year = createDate.getFullYear();

    const minuteString = minute <= 9 ? "0" + minute : minute;
    const hourString = hour <= 9 ? "0" + hour : hour;
    const monthString = month <= 9 ? "0" + month : month;
    const dayString = day <= 9 ? "0" + day : day;
    const yearShortString = year.toString().slice(2, 4);

    dateString = hourString + ":";
    dateString += minuteString + " ";
    dateString += dayString + "/";
    dateString += monthString + "/";
    dateString += yearShortString;

    return dateString;
};
