import React from 'react'
import {Image} from "semantic-ui-react";
import isoLogo from "../../assets/ios-badge.svg";
import { isAndroid } from 'react-device-detect'

export const AppstoreLinks = () => (
    <div>
        Sorry! We don't currently support mobile web, please use Musitude on a PC/Laptop or download the App available now!
        <div>{
            isAndroid ? <a href="https://play.google.com/store/apps/details?id=rocks.musitude.androidapp&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <Image alt='Get it on Google Play' centered
                       src='https://play.google.com/intl/en_gb/badges/images/generic/en_badge_web_generic.png'
                       size='medium' style={{paddingTop: '20px'}}/>
            </a> :
            <a href="https://itunes.apple.com/us/app/musitude/id1409620925?ls=1&mt=8">
            <Image src={isoLogo} size='small' centered style={{paddingTop: '20px'}}/>
            </a>
        }
        </div>
    </div>
)
