import React from "react";
import AlphabetHeader from "../../components/header/AlphabetHeader";
import { ordered, titleCase } from "../../common/util";
import Loading from "../../assets/loading_text.png";
import {
    Dimmer,
    Divider,
    Grid,
    Header,
    Image,
    List,
    Loader,
    Segment,
} from "semantic-ui-react";
import { Link } from "react-router";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import { gql, useQuery } from "@apollo/client";

const DefaultLetter = "A";

const ListView = (props) => {
    const query = createQuery(props.location.query);
    const { data, error, loading } = useQuery(query);

    if (error) {
        return null; // TODO create error component with 'Try again' button
    }

    if (loading) {
        return <LoadingNotation />;
    }

    const genre = props.location.query.genre;
    let initial = props.location.query.initial
        ? props.location.query.initial.toUpperCase()
        : DefaultLetter;

    const notationGroupedByArtist = ordered(
        groupBy(data.notation, "artist.name")
    );

    const artistComponents = map(notationGroupedByArtist, (songs, artist) => {
        const songComponents = songs.map((song, i) => {
            return (
                <List.Item key={i}>
                    <Link to={`/player/${song.id}`}>{song.title}</Link>
                </List.Item>
            );
        });

        return (
            <List.Item key={artist}>
                <b>{artist}</b>
                <List.List>{songComponents}</List.List>
            </List.Item>
        );
    });

    return (
        <Grid
            centered={true}
            padded={true}
            textAlign="left"
            className="ubuntuFont"
        >
            <Grid.Row>
                <Grid.Column>
                    {genre ? (
                        ""
                    ) : (
                        <AlphabetHeader
                            genre={genre}
                            initial={initial}
                            fetchByInitial={() => {}}
                            fetchAll={() => {}}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    {genre ? (
                        <div>
                            <Header>
                                Songs by Genre: '{titleCase(genre)}'
                            </Header>
                            <Divider />
                        </div>
                    ) : null}
                    <div className="listContainer">
                        <List>{artistComponents}</List>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

function createQuery(locationQuery) {
    if (locationQuery.initial) {
        return gql`
            query {
                notation(artistInitial: "${locationQuery.initial}") {
                    id
                    title
                    artist {
                        name
                    }
                }
            }
        `;
    } else if (locationQuery.artist) {
        return gql`
            query {
                notation(artistId: "${locationQuery.artist}") {
                    id
                    title
                    artist {
                        name
                    }
                }
            }
        `;
    } else if (locationQuery.genre) {
        return gql`
            query {
                notation(genre: "${locationQuery.genre}") {
                    id
                    title
                    artist {
                        name
                    }
                }
            }
        `;
    } else {
        return gql`
            query {
                notation(artistInitial: "A") {
                    id
                    title
                    artist {
                        name
                    }
                }
            }
        `;
    }
}

const LoadingNotation = () => {
    return (
        <Segment basic>
            <Dimmer active inverted>
                <Loader size="large">Loading Notation</Loader>
            </Dimmer>
            <Image src={Loading} />
        </Segment>
    );
};

export default ListView;
