import React from "react";
import BlockHalfLeft from "../notation/blocks/BlockHalfLeft";
import BlockHalfRight from "../notation/blocks/BlockHalfRight";
import ThreeQuarterLeft from "../notation/blocks/ThreeQuarterLeft";
import ThreeQuarterRight from "../notation/blocks/ThreeQuarterRight";
import QuarterLeft from "../notation/blocks/QuarterLeft";
import QuarterRight from "../notation/blocks/QuarterRight";
import Whole from "../notation/blocks/Whole";
import WholeShift from "../notation/blocks/WholeShift";
import Empty from "../notation/blocks/Empty";
import Sustain from "../notation/blocks/Sustain";
import OpenLeftSustain from "../notation/blocks/OpenLeftSustain";
import OpenRightSustain from "../notation/blocks/OpenRightSustain";
import RestHalfLeft from "../notation/blocks/RestHalfLeft";
import RestHalfRight from "../notation/blocks/RestHalfRight";
import { Shape, ShapesWithMargins } from "../notation/config";
import includes from "lodash/includes";
import Triplet from "../notation/blocks/Triplet";
import Quad from "../notation/blocks/Quad";

const shapesWithMargins = ShapesWithMargins;

export const DynamicNote = (props) => {
    const { cradlePosition, piece, signature } = props;
    const oddStartPosition = isOdd(cradlePosition);
    const oddDuration = isOdd(piece.duration);
    const marginClass = addMarginClass(
        piece.type,
        oddDuration,
        oddStartPosition
    );

    return (
        <span className={"dynamicNote " + marginClass}>
            {findBlock(piece, 1, cradlePosition, oddStartPosition, signature)}
        </span>
    );
};

const findBlock = (
    piece,
    index,
    cradlePosition,
    oddStartPosition,
    signature
) => {
    //Triplet and Quad notes are not a type in backend, triplet is defined if the piece.note is 3 characters
    if (piece.note.length === 3) {
        return (
            <span className={piece.displayState}>
                <Triplet
                    note1={piece.note.charAt(0)}
                    note2={piece.note.charAt(1)}
                    note3={piece.note.charAt(2)}
                    key={index.toString()}
                />
            </span>
        );
    }
    if (piece.note.length === 4) {
        return (
            <span className={piece.displayState}>
                <Quad
                    note1={piece.note.charAt(0)}
                    note2={piece.note.charAt(1)}
                    note3={piece.note.charAt(2)}
                    note4={piece.note.charAt(3)}
                    key={index.toString()}
                />
            </span>
        );
    }
    switch (piece.type) {
        case Shape.WHOLE_SHIFT:
            return (
                <span className={piece.displayState}>
                    <WholeShift note={piece.note} key={index.toString()} />
                </span>
            );
        case Shape.WHOLE:
            if (piece.note === "#") {
                return (
                    <span className={piece.displayState}>
                        <Empty key={index.toString()} />
                    </span>
                );
            } else {
                return (
                    <span className={piece.displayState}>
                        <Whole note={piece.note} key={index.toString()} />
                    </span>
                );
            }
        case Shape.SUSTAIN:
            return (
                <span className={piece.displayState + "," + piece.type}>
                    <Sustain
                        note={piece.note}
                        duration={piece.duration}
                        key={index.toString()}
                    />
                </span>
            );
        case Shape.OPEN_LEFT_SUSTAIN:
            return (
                <span className={piece.displayState + "," + piece.type}>
                    <OpenLeftSustain
                        oddStartPosition={oddStartPosition}
                        note={piece.note}
                        duration={piece.duration}
                        cradlePosition={cradlePosition}
                        key={index.toString()}
                    />
                </span>
            );
        case Shape.OPEN_RIGHT_SUSTAIN:
            return (
                <span className={piece.displayState + "," + piece.type}>
                    <OpenRightSustain
                        oddStartPosition={oddStartPosition}
                        note={piece.note}
                        duration={piece.duration}
                        cradlePosition={cradlePosition}
                        signature={signature}
                        key={index.toString()}
                    />
                </span>
            );
        case Shape.RIGHT:
            if (piece.note === "#") {
                return (
                    <span className={piece.displayState}>
                        <RestHalfRight key={index.toString()} />
                    </span>
                );
            } else {
                return (
                    <span className={piece.displayState}>
                        <BlockHalfRight
                            note={piece.note}
                            key={index.toString()}
                        />
                    </span>
                );
            }
        case Shape.LEFT:
            if (piece.note === "#") {
                return (
                    <span className={piece.displayState}>
                        <RestHalfLeft key={index.toString()} />
                    </span>
                );
            } else {
                return (
                    <span className={piece.displayState}>
                        <BlockHalfLeft
                            note={piece.note}
                            key={index.toString()}
                        />
                    </span>
                );
            }
        case Shape.THREE_QUARTER_RIGHT:
            return (
                <span className={piece.displayState}>
                    <ThreeQuarterRight
                        width="25"
                        note={piece.note}
                        key={index.toString()}
                    />
                </span>
            );
        case Shape.THREE_QUARTER_LEFT:
            return (
                <span className={piece.displayState}>
                    <ThreeQuarterLeft
                        width="25"
                        note={piece.note}
                        key={index.toString()}
                    />
                </span>
            );
        case Shape.QUARTER_RIGHT:
            return (
                <span className={piece.displayState}>
                    <QuarterRight
                        width="25"
                        note={piece.note}
                        key={index.toString()}
                    />
                </span>
            );
        case Shape.QUARTER_LEFT:
            return (
                <span className={piece.displayState}>
                    <QuarterLeft
                        width="25"
                        note={piece.note}
                        key={index.toString()}
                    />
                </span>
            );
        default:
            return "";
    }
};

const addMarginClass = (type, oddDuration, oddStartPosition) => {
    //sustain shapes for odd durations don't have margins
    if (type === Shape.SUSTAIN) {
        if (oddDuration) {
            return "";
        } else {
            return "withMargin";
        }
    }
    //partial sustain margin logic
    if (type === Shape.OPEN_LEFT_SUSTAIN) {
        if (
            (oddDuration && oddStartPosition) ||
            (!oddDuration && !oddStartPosition)
        ) {
            return "withMargin";
        } else {
            return "";
        }
    }

    if (includes(shapesWithMargins, type)) {
        return "withMargin";
    } else {
        return "";
    }
};

const isOdd = (num) => {
    return num % 2;
};
