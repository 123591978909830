import React from "react";
import Header from "../../components/olympus/components/Header";
import Footer from "../../components/olympus/components/Footer";
import TextCard from "../../components/olympus/components/TextCard";

function PrivacyPolicyView() {
    return (
        <div className="bootstrap-styles">
            <div className="olympus-styles">
                <Header title="Privacy Policy" />
                <section className="mb60 mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 m-auto col-lg-10 col-md-12 col-sm-12 col-xs-12">
                                <TextCard header="Privacy Policy">
                                    <p>
                                        Musitude Limited (“Musitude”, “we”,
                                        “us”) takes your privacy very seriously.
                                        We take all appropriate steps to limit
                                        the risk that your personal information
                                        is lost, damaged or misused. This
                                        Musitude Privacy Policy, together with
                                        Musitude’s{" "}
                                        <a href="/terms">Terms of Use </a>,
                                        covers how Musitude collects, uses and
                                        stores your personal information. Please
                                        read it carefully. It will help you make
                                        informed decision on the use of your
                                        personal information.
                                    </p>
                                </TextCard>
                                <TextCard header="Highlights of What You Need to Know">
                                    <p>
                                        By using the Musitude Service, you
                                        agree:
                                        <ol style={{ marginLeft: "20px" }}>
                                            <li>
                                                {" "}
                                                To Musitude collecting, using
                                                and storing your information,
                                                including personal information,
                                                so we can provide you with a
                                                personalised experience. This
                                                may include some advertising as
                                                part of the Musitude Service.
                                            </li>
                                            <li>
                                                {" "}
                                                To the use of cookies and other
                                                technologies to better
                                                understand your use of our site.
                                            </li>
                                            <li>
                                                That you may change or adjust
                                                the information we collect
                                                (including cookies) as further
                                                set out in this Privacy Policy.
                                            </li>
                                        </ol>
                                        If you don't agree with the terms of
                                        this Privacy Policy, then please do not
                                        use the Musitude Service.
                                    </p>
                                </TextCard>
                                <TextCard header="Information We Collect About You">
                                    <p>
                                        We collect and store the personal
                                        information you directly provide to us.
                                        When you register, you will be asked for
                                        your name and email address. You will
                                        also be asked to set up a password.{" "}
                                        <br /> <br />
                                        When you use the Musitude Service, we
                                        may use a variety of technologies
                                        (including “cookies”) that collect
                                        information about how you access and use
                                        the Musitude Service. We collect this
                                        information and it identifies you from
                                        your internet protocol (IP) address of
                                        your device.
                                        <br />
                                        This information may include:
                                        <ol style={{ marginLeft: "20px" }}>
                                            <li>
                                                {" "}
                                                Your interactions with the
                                                Musitude Service, such as pieces
                                                played, interactions with songs,
                                                favourites, other content
                                                enjoyed, and advertising and
                                                products made available through
                                                the Musitude Service.
                                            </li>
                                            <li>
                                                {" "}
                                                Details of any queries you may
                                                make.
                                            </li>
                                            <li>
                                                Technical data, which may
                                                include URL information, cookie
                                                data, your internet protocol
                                                (IP) address, devices you are
                                                using to access the Musitude
                                                Service, your network and
                                                browser information, and other
                                                technical information related to
                                                your use of the Musitude
                                                Service.
                                            </li>
                                            <li>
                                                4. Broad, non-specific location
                                                information derived from your
                                                internet protocol (IP) address.
                                            </li>
                                        </ol>
                                        We may also collect general statistical
                                        data about users’ browsing activities
                                        and patterns that do not identify any
                                        individual.
                                    </p>
                                </TextCard>
                                <TextCard header="How We Use Your Information">
                                    <p>
                                        In accordance with the permissions you
                                        give us to collect your information,
                                        Musitude may use your information,
                                        including your personal information:
                                        <ul
                                            style={{
                                                listStyle: "square",
                                                marginLeft: "20px",
                                            }}
                                        >
                                            <li>
                                                To provide you with a
                                                personalised experience,
                                                including making suggestions
                                                such as musical compositions
                                                that you may like to try. This
                                                may also include providing you
                                                with other features, information
                                                or content which is influenced
                                                by your preferences, location,
                                                or other information related to
                                                your use of the Musitude
                                                Service.
                                            </li>
                                            <li>
                                                To provide you with certain
                                                advertisements or information
                                                that may be of interest to you.
                                            </li>
                                            <li>
                                                To ensure the technical
                                                functionality of the Musitude
                                                Service and to develop new
                                                features and functionality.
                                            </li>
                                            <li>
                                                To analyse your use of the
                                                Musitude Service, including your
                                                interaction with products,
                                                services, applications and
                                                advertising.
                                            </li>
                                            <li>
                                                To communicate with you about
                                                the Musitude Service, including
                                                via emails, notifications or
                                                other messages.
                                            </li>
                                            <li>
                                                To enforce this Privacy Policy
                                                and Terms of Use, including
                                                protecting the rights, property,
                                                or safety of Musitude, its
                                                users, its licensors, or any
                                                other person; or the
                                                copyright-protected content of
                                                the Musitude Service.
                                            </li>
                                            <li>
                                                As otherwise stated in this
                                                Privacy Policy.
                                            </li>
                                        </ul>
                                        Musitude will hold your information for
                                        only as long as is necessary to provide
                                        you with Musitude Service and as set out
                                        above.
                                        <br />
                                        Any personal information and data stored
                                        for the Musitude Service will be
                                        transferred to and stored at a location
                                        inside the European Economic Area
                                        (“EEA”). It may be processed by Musitude
                                        staff operating inside or outside the
                                        EEA.
                                        <br />
                                        <br />
                                        We will take all reasonable steps
                                        necessary to ensure that your data is
                                        treated securely and in accordance with
                                        Musitude’s Privacy Policy.
                                    </p>
                                </TextCard>
                                <TextCard header="How Your Information is Shared">
                                    <p>
                                        The Musitude Service allows you access
                                        to musical compositions and other
                                        content that may be owned by third party
                                        rights holders. Musitude shares
                                        information with the rights holders that
                                        license this content to Musitude,
                                        however the data that Musitude shares is
                                        anonymised and does not personally
                                        identify you.
                                        <br />
                                        In addition, Musitude may also share
                                        your information with third parties for
                                        these limited purposes:
                                        <ul
                                            style={{
                                                listStyle: "square",
                                                marginLeft: "20px",
                                            }}
                                        >
                                            <li>
                                                To allow a merger, acquisition
                                                or sale of all or a portion of
                                                our assets.
                                            </li>
                                            <li>
                                                To respond legal process (e.g. a
                                                court order) if we believe in
                                                good faith that it is necessary
                                                to do so to comply with
                                                applicable law, to protect the
                                                safely of any person, to protect
                                                the right and property of
                                                Musitude, including the
                                                enforcement of our Terms of Use.
                                            </li>
                                            <li>
                                                To address security or technical
                                                issues of the Musitude Service.
                                            </li>
                                        </ul>
                                        Musitude may use third parties to
                                        process your information on Musitude’s
                                        behalf (for example to provide services
                                        or analysis). Musitude requires these
                                        third parties to comply strictly with
                                        Musitude’s instructions and security and
                                        confidentiality obligations consistent
                                        with this Privacy Polity and applicable
                                        law. Where a third party processes user
                                        data on its own behalf, its processing
                                        is subject to its own Privacy Policy and
                                        applicable law.
                                    </p>
                                </TextCard>
                                <TextCard header="Third Party Sites and Services">
                                    <p>
                                        Any third-party links on the Musitude
                                        Service, including any advertising
                                        links, may involve such third parties
                                        collecting your information, which may
                                        include such things as your location
                                        data or contact details. These
                                        third-party sites are governed by their
                                        own privacy policies. Musitude does not
                                        control or accept any responsibility or
                                        liability for these policies or
                                        practices. Please review these policies
                                        before you submit any personal data to
                                        these third-party sites.
                                    </p>
                                </TextCard>
                                <TextCard header="Children’s Information">
                                    <p>
                                        The Musitude Service is not intended for
                                        children under 13 years old. Musitude
                                        does not knowingly collect personal
                                        information from children under 13 years
                                        old or under the applicable age limit.{" "}
                                        <br /> <br />
                                        If you are a parent or guardian of a
                                        child using the Musitude Service and you
                                        become aware that your child has
                                        provided personal information to
                                        Musitude via our sign up process or
                                        otherwise, please contact us at
                                        <a href="mailTo:info@musitude.com">
                                            {" "}
                                            info@musitude.com{" "}
                                        </a>
                                        .<br /> <br />
                                        You may request removal of your child’s
                                        personal information and Musitude will
                                        use reasonable efforts to comply with
                                        your request and any other rights you
                                        have.
                                    </p>
                                </TextCard>
                                <TextCard header="Cookies and Related Technologies">
                                    <p>
                                        <b>
                                            What are cookies and related
                                            technologies
                                        </b>{" "}
                                        <br />
                                        A cookie is a small text file that a
                                        website saves on your computer or mobile
                                        device when you visit the site. It
                                        enables the website to remember your
                                        actions and preferences (such as login,
                                        language, font size and other display
                                        preferences), so you do not have to
                                        re-entering them whenever you return to
                                        the site or browse from one page to
                                        another. There are related technologies
                                        with similar functionality.
                                        <br />
                                        <br />
                                        <b>
                                            How we use cookies and other
                                            technologies
                                        </b>{" "}
                                        <br />
                                        Performance and analytic cookies are
                                        necessary to allow the Musitude Service
                                        to operate as you have requested. We use
                                        this information to let us recognise you
                                        and provide you with services
                                        accordingly. We also use this
                                        information to maintain, operate, and
                                        continually improve the Musitude
                                        Service.
                                        <br />
                                        <br />
                                        Functional cookies operate certain
                                        functions of the Musitude Service in
                                        accordance with your choices and user
                                        behaviour. We use this information to
                                        provide you with the Musitude Service
                                        you have requested, such as preferences
                                        and reminding you of content you have
                                        used.
                                        <br />
                                        <br />
                                        Targeting advertising cookies may be
                                        used to provide you with advertisements
                                        that may be relevant to you.
                                        <br />
                                        <br />
                                        Musitude will not use cookies or related
                                        technologies for any purpose other than
                                        as stated in our Privacy Policy.
                                    </p>
                                </TextCard>
                                <TextCard header="Managing Your Cookie Preferences">
                                    <p>
                                        Many web browsers allow you to manage
                                        your cookie preferences. You can set
                                        your browser to refuse cookies or to
                                        delete certain cookies. However if you
                                        disable cookies some functionality of
                                        the Musitude Service may not be fully
                                        available to you and/or you may need to
                                        re-enter your log in information. You
                                        may be able to manage other related
                                        technologies in the same way that you
                                        manage cookies using your browser’s
                                        preferences. <br /> <br />
                                        For details about managing your cookies
                                        and related technologies, check your
                                        browser details or see{" "}
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://youronlinechoices.eu/"
                                        >
                                            https://allaboutcookies.org{" "}
                                        </a>{" "}
                                        or{" "}
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://allaboutcookies.org/"
                                        >
                                            https://youronlinechoices.eu
                                        </a>
                                        .
                                    </p>
                                </TextCard>
                                <TextCard header="Your Right To Your Personal Information">
                                    <p>
                                        You can view and amend the information
                                        we keep about you. You can also request
                                        that Musitude to delete the personal
                                        information it holds about you.
                                        <br /> <br />
                                        In certain jurisdictions (such as within
                                        the EU) you a have the right to request
                                        a copy of the personal information
                                        Musitude holds about you and to have any
                                        inaccuracies corrected. Each
                                        jurisdiction has different processes and
                                        fees for such information requests.
                                        Musitude will use reasonable efforts
                                        consistent with our legal duty to
                                        supply, correct or delete personal
                                        information about you in our files.
                                        <br /> <br />
                                        If you have questions about your privacy
                                        on the Service, this privacy policy, or
                                        information we have about you, please
                                        contact us at
                                        <a href="mailTo:info@musitude.com">
                                            {" "}
                                            info@musitude.com
                                        </a>
                                        .
                                        <br /> <br />
                                        You can also contact our privacy
                                        representative by sending a letter at:
                                        <br />
                                        Musitude
                                        <br />
                                        14 King Charles House
                                        <br />
                                        Cavalier Court, Bumpers Farm
                                        <br />
                                        Chippenham, SN14 6LH
                                        <br />
                                        United Kingdom
                                    </p>
                                </TextCard>
                                <TextCard header="Changes to Our Privacy Policy">
                                    <p>
                                        Musitude may change, modify or remove
                                        portions of the Privacy Policy at any
                                        time. When we update our Privacy Policy,
                                        we will indicate the “last modified on”
                                        at the end of the Privacy Policy.
                                    </p>
                                </TextCard>
                                <TextCard header="Contact Us">
                                    <p>
                                        Please contact Musitude at{" "}
                                        <a href="mailTo:info@musitude.com">
                                            info@musitude.com{" "}
                                        </a>{" "}
                                        if you have any questions or comments
                                        regarding these Terms of Use.
                                        <br /> <br />
                                        Musitude Limited, a company registered
                                        in England and Wales with company number
                                        09215793. Our registered address is 14
                                        King Charles House, Cavalier Court,
                                        Bumpers Farm, Chippenham, SN14 6LH,
                                        United Kingdom.
                                        <br /> <br />
                                        <i>
                                            Information in the Musitude Terms of
                                            Use and Privacy Policy is subject to
                                            change without notice. This Privacy
                                            Policy- last modified on 23 February
                                            2018.
                                            <br /> <br />
                                            Copyright © 2018 Musitude Limited.
                                            All rights reserved.
                                        </i>
                                    </p>
                                </TextCard>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </div>
    );
}

export default PrivacyPolicyView;
