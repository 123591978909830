import React, { Component } from 'react'

import { browserHistory } from 'react-router'
import logo from '../../../assets/Musitude-Icon-White.svg'

class Header extends Component {
    render() {
        return (
            <div className="bootstrap-styles">
                <div className="olympus-styles">
                    <div className="stunning-header bg-purple-dark-opacity">
                        <div style={{ padding: '15px' }}>
                            <img
                                className="cursorHover"
                                style={{ width: '40px' }}
                                src={logo}
                                alt=""
                                onClick={() => browserHistory.push('/')}
                            />
                        </div>
                        <div className="header-spacer--standard"/>

                        <div className="stunning-header-content">
                            <h1 className="stunning-header-title">
                                {this.props.title}
                            </h1>
                            <ul className="breadcrumbs">
                                <li className="breadcrumbs-item">
                                    <a href="/">Home</a>
                                    <span className="icon breadcrumbs-custom">
                                        /
                                    </span>
                                </li>
                                <li className="breadcrumbs-item active">
                                    <span>{this.props.title}</span>
                                </li>
                            </ul>
                        </div>
                        <div
                            className="content-bg-wrap"
                            style={{ position: 'relative' }}
                        >
                            <div className="content-bg stunning-header-bg1"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header
