import React from "react";
import Header from "../../components/olympus/components/Header";
import Footer from "../../components/olympus/components/Footer";
import TextCard from "../../components/olympus/components/TextCard";

function TermsAndConditions() {
    return (
        <div className="bootstrap-styles">
            <div className="olympus-styles">
                <Header title="Terms of Use" />
                <section className="mb60 mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 m-auto col-lg-10 col-md-12 col-sm-12 col-xs-12">
                                <TextCard header="Terms of Use">
                                    <p>
                                        These terms of use ("<b>Terms of Use</b>
                                        ") apply to your access and use of the
                                        Musitude’s website www.musitude.com,
                                        applications, and related services (the{" "}
                                        <b>"Musitude Service"</b>). Our{" "}
                                        <a href="/privacy"> Privacy Policy </a>{" "}
                                        is also part of these Terms of Use.{" "}
                                        <br />
                                        <br />
                                        <i>
                                            Content in the Musitude Service,
                                            including the music notation and
                                            compositions, are the property of
                                            Musitude Limited (“Musitude”, “we”,
                                            “us”) and its licensors. All rights
                                            are reserved.
                                        </i>
                                        <br />
                                        <br />
                                        <b>
                                            BY USING THE MUSITUDE SERVICE, YOU
                                            AGREE TO THESE TERMS OF USE AND
                                            PRIVACY POLICY.
                                        </b>{" "}
                                        <br />
                                        Please read them carefully. They contain
                                        important information on how we collect
                                        and use your personal and non-personal
                                        information.
                                        <br />
                                        <br />
                                        The Musitude Service in intended for
                                        people 13 years old or older. In
                                        registering: (i) if you are 18 years or
                                        older you agree to our Terms of Use; or
                                        (ii) if you are between 13-18 years old
                                        please get your parent or guardian’s
                                        consent to register for the Musitude
                                        Service and agreement of our Terms of
                                        Use.
                                    </p>
                                </TextCard>
                                <TextCard header="Enjoying the Musitude Service">
                                    <p>
                                        Musitude lets you enjoy reading and
                                        playing music in a whole new way. Much
                                        of the Musitude Service is
                                        free-of-charge. We will ask you to
                                        register and provide your name and email
                                        address. We use this information to give
                                        you access to and customise your
                                        Musitude Service, and to collect some
                                        information about our users. Please see
                                        our{" "}
                                        <a href="/privacy"> Privacy Policy</a>{" "}
                                        for more information on how we use your
                                        information.
                                        <br />
                                        <br />
                                        Other services may be added in the
                                        future and will update these Term of Use
                                        and Privacy Policy accordingly.
                                        <br />
                                        <br />
                                        Musitude may allow you access to try the
                                        Musitude Service on a limited bases
                                        without registering. You will still need
                                        to agree to these Terms of Use.
                                    </p>
                                </TextCard>
                                <TextCard header="Musitude Content and Intellectual Property Rights">
                                    <p>
                                        Musitude and its licensors retain
                                        ownership in all intellectual property
                                        rights in the content and material as
                                        part of the Musitude Service. This
                                        includes without limit all Musitude and
                                        licensors’ notations, trademarks,
                                        designs, service marks, logos, and brand
                                        features. The Musitude Service may also
                                        contain and develop a database of music
                                        you have performed as part of enjoying
                                        the Musitude Service. Some content and
                                        materials (for example an original music
                                        composition) may be licensed to you
                                        under relevant third-party licences or
                                        it may be out of copyright.
                                        <br />
                                        <br />
                                        The Musitude Service’s content, material
                                        and databases are protected by
                                        copyright, registered designs, design
                                        rights, trademark, design patents,
                                        database rights and related protections
                                        in the EU, UK, US and other
                                        jurisdictions around the world.
                                    </p>
                                </TextCard>
                                <TextCard header="Third-Party Content and Links">
                                    <p>
                                        Third-party content and materials may
                                        appear as part of the Musitude Service
                                        (such as advertising or information
                                        about an artist). Musitude is not
                                        responsible for and assumes no liability
                                        for any third-party content.
                                        <br />
                                        <br />
                                        The Musitude Service may contain links
                                        to third-party websites, social media,
                                        apps and related resources. These links
                                        are provided for your convenience and
                                        are not under Musitude’s control.
                                        Musitude is not responsible for the
                                        content, privacy policies or practices
                                        of such linked sites. If you use these
                                        links we encourage you to review their
                                        terms of use and privacy policies.
                                        <br />
                                        <br />
                                        If you are a Musitude user, you may post
                                        links (without the Musitude notation) of
                                        music pieces you have played to your
                                        social media, forums or other sites.
                                        From a third-party site, You may link to
                                        the Musitude home page only. No deep
                                        linking for framing in another site is
                                        allowed without explicit written
                                        permission of Musitude. Any linking to
                                        the Musitude Service must not damage the
                                        reputation of Musitude, nor should it be
                                        done in a way to suggest any form of
                                        association, approval or endorsement by
                                        Musitude.
                                        <br />
                                        <br />
                                        Musitude reserves the right to withdraw
                                        linking permission without notice.
                                    </p>
                                </TextCard>
                                <TextCard header="Limitations on Your Use of the Musitude Website">
                                    <p>
                                        You may use the Musitude Service and its
                                        materials only for lawful purposes. You
                                        are prohibited from using the Musitude
                                        Service in these ways:
                                        <ul
                                            style={{
                                                listStyle: "square",
                                                marginLeft: "20px",
                                            }}
                                        >
                                            <li>
                                                Copying, reproducing,
                                                redistributing or otherwise
                                                making available to the public
                                                any part of the Musituse Service
                                                or its content which is not
                                                expressly permitted under these
                                                Terms of Use or applicable law.
                                            </li>
                                            <li>
                                                Do anything that infringes on
                                                the intellectual property rights
                                                of Musitude, its licensors or
                                                any other related content.
                                            </li>
                                            <li>
                                                Reverse engineering,
                                                decompiling, disassembling,
                                                modifying or creating a
                                                derivative work based on the
                                                Musitude Service, its notation,
                                                and its content.
                                            </li>
                                            <li>
                                                Circumventing or manipulating
                                                any technology used by the
                                                Musitude Service.
                                            </li>
                                            <li>
                                                Provide your password or
                                                registration details to any
                                                other person.
                                            </li>
                                            <li>
                                                Selling user account
                                                registration, or otherwise
                                                accepting any compensation
                                                (financial or otherwise) for
                                                using the Musitude Service.
                                            </li>
                                            <li>
                                                Sending, or procuring the
                                                sending of, any unsolicited or
                                                unauthorised advertising or
                                                promotional material or any
                                                other form of similar
                                                solicitation.
                                            </li>
                                            <li>
                                                In any way that breaches any
                                                applicable local, national or
                                                international law or regulation.
                                            </li>
                                            <li>
                                                In any way that is unlawful or
                                                fraudulent.
                                            </li>
                                            <li>
                                                For the purpose of harming or
                                                attempting to harm minors in any
                                                way.
                                            </li>
                                            <li>
                                                To knowingly transmit any data,
                                                send or upload any material that
                                                contains viruses, Trojan horses,
                                                worms, time-bombs, keystroke
                                                loggers, spyware, adware or any
                                                other harmful programs or
                                                similar computer code designed
                                                to adversely affect the
                                                operation of any computer
                                                software or hardware.
                                            </li>
                                            <li>
                                                Include any personal details or
                                                personal data of a third party,
                                                or to solicit personal data.
                                            </li>
                                        </ul>
                                        Any use of the Musitude Service in
                                        conflict with these limitations or that
                                        breach the Term of Use may result in
                                        immediate suspension or termination of
                                        your Musitude Service.
                                        <br />
                                        <br />
                                        If you believe any actions as part of
                                        the Musitude Service may infringe on the
                                        intellectual property rights of others,
                                        or may be in breach of these Terms of
                                        Use, please contact Musitude at{" "}
                                        <a href="mailTo: info@musitude.com">
                                            {" "}
                                            info@musitude.com
                                        </a>
                                        . Musitude at its sole discretion take
                                        actions as its sees fit and in
                                        accordance with these Terms of Use.
                                    </p>
                                </TextCard>
                                <TextCard header="Your Privacy and Information Collected">
                                    <p>
                                        To view Musitude’s Privacy Policy,{" "}
                                        <a href="privacy"> click here </a>
                                        <br />
                                        <br />
                                        When you choose to register for the
                                        Musitude Service, we ask you to provide
                                        us with certain personal information
                                        such as your name and email address.
                                        Musitude will process your information
                                        in accordance with our Privacy Policy.
                                        <br />
                                        <br />
                                        We use “cookies” and other technologies
                                        to personalise Musitude Service and to
                                        help us better understand our users’
                                        behaviour. Find out more about cookies
                                        and your options for their use in the
                                        Musitude{" "}
                                        <a href="privacypolicy">
                                            {" "}
                                            Privacy Policy
                                        </a>
                                        .
                                        <br />
                                        <br />
                                        By using the Musitude Service, you agree
                                        to our use of your information. The
                                        specific jurisdiction you are located in
                                        may have its own rules, rights and
                                        obligations for privacy and processing
                                        of personal information.
                                    </p>
                                </TextCard>
                                <TextCard header="Your Registration and Passwords Security">
                                    <p>
                                        You will need to register to use the
                                        Musitude Service and create a user name
                                        and password. You are entirely
                                        responsible for maintaining the
                                        confidentiality of your registration and
                                        password information. You agree to
                                        notify Musitude immediately if you
                                        become aware of any unauthorised use of
                                        your account. Musitude is not liable for
                                        any loss or damage arising from your
                                        failure to comply with these
                                        obligations.
                                    </p>
                                </TextCard>
                                <TextCard header="Our Service and Modifications">
                                    <p>
                                        Musitude will make reasonable efforts to
                                        keep the Musitude Service operational.
                                        However certain technical issues or
                                        maintenance may, from time to time,
                                        result in temporary interruptions in the
                                        Musitude Service. Musitude reserves the
                                        right to modify or discontinue, on a
                                        temporary or permanent basis, some or
                                        all of the functions and features of the
                                        Musitude Service, without notice to you.
                                        <br /> <br />
                                        Musitude and its licensors may, from
                                        time to time, modify or remove any such
                                        content without notice to you.
                                    </p>
                                </TextCard>
                                <TextCard header="No Official User Support">
                                    <p>
                                        Musitude will use reasonable efforts to
                                        support the Musitude Service, but we do
                                        not provide user with direct support
                                        services.
                                        <br /> <br />
                                        If you have a specific question on the
                                        Musitude Service, please contact us at{" "}
                                        <a href="mailTo: info@musitude.com">
                                            info@musitude.com
                                        </a>
                                        . We will make reasonable effort to
                                        respond to your request in a timely
                                        manner, but we cannot make any
                                        guarantees of any kind that questions
                                        will be responded to within a particular
                                        time frame or that we will satisfactory
                                        answer your questions.
                                        <br /> <br />
                                        The Musitude Service, and any content,
                                        is provided on an “as is” basis without
                                        any support or warranties of any kind.
                                    </p>
                                </TextCard>
                                <TextCard header="Limitation of Liability">
                                    <p>
                                        Except where prohibited by law, in no
                                        event will Musitude be liable to you for
                                        any damages or losses you may incur as
                                        the result of your use of the Musitude
                                        Service, including any indirect,
                                        consequential, exemplary, incidental or
                                        punitive damages (including lost
                                        profits, loss of goodwill, loss of
                                        data), even if Mustude has been advised
                                        of the possibility of such damages.
                                        <br /> <br />
                                        Musitude will not be liable for any loss
                                        or damage caused by a virus,
                                        denial-of-service attack or other
                                        technologically harmful material that
                                        may disrupt your computer equipment,
                                        computer programs, data or other
                                        material as the result of your use of
                                        the Musitude Service or any third-party
                                        link from the Musitude Service.
                                        <br /> <br />
                                        This limit of liability does not affect
                                        the Musitude’s liability for death or
                                        personal injury arising from our
                                        negligence, our liability for fraudulent
                                        misrepresentation, or any other
                                        liability which cannot be excluded or
                                        limited under applicable law.
                                        <br /> <br />
                                        Disclaimer:
                                        <br />
                                        MUSITUDE DOES NOT PROMISE THAT THE
                                        MUSITUDE SERVCIE OR ANY CONTENT, SERVICE
                                        OR FEATURE OF THE MUSITUDE SERVICE WILL
                                        BE ERROR-FREE OR UNINTERRUPTED, OR THAT
                                        ANY DEFECTS WILL BE CORRECTED, OR THAT
                                        YOUR USE OF THE MUSITUDE SERVICE WILL
                                        PROVIDE SPECIFIC RESULTS. THE MUSITUDE
                                        SERVICE AND ITS CONTENT ARE DELIVERED ON
                                        AN "AS-IS" AND "AS-AVAILABLE" BASIS.
                                        <br /> <br />
                                        ALL INFORMATION PROVIDED AS PART OF THE
                                        MUSITUDE SERVICE IS SUBJECT TO CHANGE
                                        WITHOUT NOTICE. MUSITUDE CANNOT ENSURE
                                        THAT ANY FILES OR OTHER DATA YOU
                                        DOWNLOAD AS PART OF THE MUSITUDE SERVICE
                                        WILL BE FREE OF VIRUSES OR CONTAMINATION
                                        OR DESTRUCTIVE FEATURES. MUSITUDE
                                        DISCLAIMS ALL WARRANTIES, EXPRESS OR
                                        IMPLIED, INCLUDING ANY WARRANTIES OF
                                        ACCURACY, NON-INFRINGEMENT,
                                        MERCHANTABILITY AND FITNESS FOR A
                                        PARTICULAR PURPOSE. MUSITUDE DISCLAIMS
                                        ANY AND ALL LIABILITY FOR THE ACTS,
                                        OMISSIONS AND CONDUCT OF ANY THIRD
                                        PARTIES IN CONNECTION WITH OR RELATED TO
                                        YOUR USE OF THE MUSITUDE SERVICE. YOU
                                        ASSUME TOTAL RESPONSIBILITY FOR YOUR USE
                                        OF THE MUSITUDE SERVICE AND ANY LINKED
                                        SITES. YOUR SOLE REMEDY AGAINST MUSITUDE
                                        FOR DISSATISFACTION WITH THE MUSITUDE OR
                                        ANY CONTENT IS TO STOP USING THE
                                        MUSITUDE SERVICE OR ANY SUCH CONTENT.
                                        THIS LIMITATION OF RELIEF IS A PART OF
                                        THE BARGAIN BETWEEN THE PARTIES.
                                    </p>
                                </TextCard>
                                <TextCard header="Suspension and Termination">
                                    <p>
                                        Musitude in its sole discretion will
                                        determine when you are in breach of
                                        these Terms of Use, and if a breach does
                                        occur Musitude may take such action as
                                        Musitude deems appropriate.
                                        <br /> <br />
                                        Your failure to comply with these Terms
                                        of Use constitutes a material breach and
                                        may result in Musitude taking all or any
                                        of the following actions: <br />
                                        <ul
                                            style={{
                                                listStyle: "square",
                                                marginLeft: "20px",
                                            }}
                                        >
                                            <li>
                                                Immediate, temporary or
                                                permanent withdrawal of your
                                                right to use the Musitude
                                                Service.
                                            </li>
                                            <li>
                                                Issue you with a formal warning.
                                            </li>
                                            <li>
                                                Legal action or proceedings
                                                against you for reimbursement of
                                                all costs (including, but not
                                                limited to, reasonable
                                                administrative and legal costs
                                                and/or on an indemnity basis)
                                                resulting from such a breach.
                                            </li>
                                        </ul>
                                        <br /> <br />
                                        These Terms of Use constitute the entire
                                        agreement between you and Musitude with
                                        regard to your use of the Musitude
                                        Service. Musitude reserves the right to
                                        change, modify, add or remove portions
                                        of these Terms of Use at any time (as
                                        indicated by the “last modified date” at
                                        the end of these Terms of Use). Please
                                        review these Terms of Use on a regular
                                        basis.
                                    </p>
                                </TextCard>
                                <TextCard header="Choice of Law">
                                    <p>
                                        Unless otherwise required by a mandatory
                                        law of a local jurisdiction, these Terms
                                        of Use and any depute or claim arising
                                        out of or in connection with them, shall
                                        be governed by the laws of England and
                                        Wales, and the English courts shall have
                                        exclusive jurisdiction to hear such a
                                        dispute or claim.
                                    </p>
                                </TextCard>
                                <TextCard header="Contact Us">
                                    <p>
                                        Please contact Musitude at{" "}
                                        <a href="mailTo:info@musitude.com">
                                            info@musitude.com{" "}
                                        </a>{" "}
                                        if you have any questions or comments
                                        regarding these Terms of Use.
                                        <br /> <br />
                                        Musitude Limited, a company registered
                                        in England and Wales with company number
                                        09215793. Our registered address is 14
                                        King Charles House, Cavalier Court,
                                        Bumpers Farm, Chippenham, SN14 6LH,
                                        United Kingdom.
                                        <br /> <br />
                                        <i>
                                            Information in the Musitude Terms of
                                            Use and Privacy Policy is subject to
                                            change without notice. These Terms
                                            of Use- last modified on 23 February
                                            2018.
                                            <br /> <br />
                                            Copyright © 2018 Musitude Limited.
                                            All rights reserved.
                                        </i>
                                    </p>
                                </TextCard>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </div>
    );
}

export default TermsAndConditions;
