import React from "react";
import DynamicNotationContainer from "../../components/notation/DynamicNotationContainer";
import { Grid } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import RecentlyAdded from "./RecentlyAdded";
import Navigation from "../../components/olympus/components/Navigation";
import Footer from "../../components/olympus/components/Footer";
import { gql, useQuery } from "@apollo/client";

const instruments = require("../../assets/notation/instruments.json");

export default function Player(props) {
    const query = fetchNotationQuery(props.params.id);
    const { data, loading, error } = useQuery(query);

    if (loading) {
        return <LoadingNotation bus={props.bus} />;
    } else if (error || !data.notation || data.notation.length === 0) {
        return null; // TODO
    } else {
        const notation = { ...data.notation[0] };
        notation.variations = notation.variations.map((v) => {
            return {
                ...v,
                instrument: instruments
                    .filter((i) => i.name === v.instrument)
                    .pop(),
            };
        });

        const notationContainer = (
            <DynamicNotationContainer
                bus={props.bus}
                notation={notation}
                instruments={instruments}
            />
        );
        return (
            <div>
                <Helmet>
                    <title>{`${notation.title} | Musitude`}</title>
                    <meta
                        name="description"
                        content={`Play ${notation.title} with your qwerty keyboard as an instrument`}
                    />
                </Helmet>
                <Navigation bus={props.bus} />
                <Grid stackable columns={2} padded={true}>
                    <Grid.Column mobile={16} tablet={12} computer={13}>
                        {notationContainer}
                    </Grid.Column>
                    <Grid.Column
                        mobile={16}
                        tablet={4}
                        computer={3}
                        style={{ paddingLeft: "0" }}
                    >
                        <RecentlyAdded />
                    </Grid.Column>
                </Grid>
                <Footer />
            </div>
        );
    }
}

const LoadingNotation = (props) => {
    return (
        <div>
            <Navigation bus={props.bus} />
            <Grid stackable columns={2} padded={true}>
                <Grid.Column mobile={16} tablet={12} computer={13} />
            </Grid>
        </div>
    );
};

function fetchNotationQuery(id) {
    return gql`
        query {
            notation(id: "${id}") {
                id
                title
                artist {
                    name
                    id
                    imageHref
                }
                variations {
                    body
                    cradles {
                        pieces {
                            duration
                            note
                            type
                        }
                    }
                    difficulty
                    id
                    instrument
                    playStyle
                    range
                    signature
                    tempo
                    type {
                        displayValue
                        value
                    }
                }
            }
        }
    `;
}
