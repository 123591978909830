import React, { Component } from 'react'
import CookieBanner from 'react-cookie-banner'

const styles = {
    banner: {
        height: 57,
        backgroundSize: '30px 30px',
        backgroundColor: '#2185d0',
        fontSize: '13px',
        fontWeight: 600,
        position: 'absolute',
        top: 'calc(100vh - 57px)'
    },
    button: {
        border: '1px solid white',
        borderRadius: 4,
        width: 66,
        height: 32,
        lineHeight: '32px',
        background: 'transparent',
        color: 'white',
        fontSize: '14px',
        fontWeight: 600,
        opacity: 1,
        right: 20,
        marginTop: -18,
    },
    message: {
        display: 'block',
        padding: '9px 67px',
        lineHeight: 1.3,
        textAlign: 'left',
        color: 'white',
    },
    link: {
        textDecoration: 'none',
        fontWeight: 'bold',
    },
}
const message = 'Musitude uses cookies, by continuing to browse the site you\'re agreeing to our use of cookies.'

class CookieNotifier extends Component {

    render() {
        return <div>
            <CookieBanner
                styles={styles}
                message={message}
                buttonMessage='Close'
                dismissOnScroll={true}
            />
        </div>
    }
}

export default CookieNotifier
