import { Grid, Image, Modal } from 'semantic-ui-react'
import React, { Component } from 'react'
import pagingImg from '../../assets/guide/paging.jpg'
import Play from '../../assets/icons/player/play.svg'
import RangeIcon from '../../assets/icons/player/range.svg'
import Speed from '../../assets/icons/player/speed.svg'
import Sound from '../../assets/icons/player/sound.svg'
import Sheet from '../../assets/icons/player/sheet.svg'
import Piano from '../../assets/icons/instruments/piano.svg'

class HelpModal extends Component {
    render() {
        return (
            <Modal
                dimmer="blurring"
                trigger={this.props.trigger}
                size="large"
                closeIcon
            >
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid stackable divided>
                            <Grid.Column width={8} className="marginAround">
                                <Modal.Header as="h1" className="ubuntuFont">
                                    Musitude help guide
                                </Modal.Header>


                                <Modal.Header
                                    as="h2"
                                    className="ubuntuFont"
                                    style={{ marginBottom: '25px' }}
                                >
                                    Music Pages
                                </Modal.Header>
                                <Grid divided>
                                    <Grid.Column width={4}>
                                        <Image
                                            middle
                                            aligned
                                            src={Sound}
                                            size="mini"
                                        />
                                    </Grid.Column>
                                    <Grid.Column
                                        width={12}
                                        className="helpMargin ubuntuFont"
                                    >
                                        Sound on/off: Turn the keyboard
                                        activated instrument sound on and off.
                                    </Grid.Column>
                                </Grid>
                                <Grid divided>
                                    <Grid.Column width={4}>
                                        <Image
                                            middle
                                            aligned
                                            src={Speed}
                                            size="mini"
                                        />
                                    </Grid.Column>
                                    <Grid.Column
                                        width={12}
                                        className="helpMargin ubuntuFont"
                                    >
                                        Speed: Speed selector for auto-play
                                        feature.
                                    </Grid.Column>
                                </Grid>
                                <Grid divided>
                                    <Grid.Column width={4}>
                                        <Image
                                            middle
                                            aligned
                                            src={RangeIcon}
                                            size="mini"
                                        />
                                    </Grid.Column>
                                    <Grid.Column
                                        width={12}
                                        className="helpMargin ubuntuFont"
                                    >
                                        Range: Move the keyboard note range up
                                        or down an octave at a time.
                                    </Grid.Column>
                                </Grid>
                                <Grid divided>
                                    <Grid.Column width={4}>
                                        <Image src={Play} size="mini"/>
                                    </Grid.Column>
                                    <Grid.Column
                                        width={12}
                                        className="helpMargin"
                                    >
                                        <p className="ubuntuFont">
                                            Play: Click the play button to
                                            access the auto-play options.
                                        </p>
                                        <p className="ubuntuFont">
                                            Autoplay: With auto-play you can
                                            have Musitude play a piece for you
                                            so you can hear how it should sound.
                                            If the piece is a duet or ensemble
                                            (two or more parts), you can have
                                            Musitude play the whole piece (all
                                            the parts, including your own). Play
                                            any of the individual parts you
                                            choose. Or play ‘all’ the other
                                            (accompanying) parts.
                                        </p>
                                    </Grid.Column>
                                </Grid>

                                <Grid divided>
                                    <Grid.Column width={4}>
                                        <Image
                                            middle
                                            aligned
                                            src={Sheet}
                                            size="mini"
                                        />
                                    </Grid.Column>
                                    <Grid.Column
                                        width={12}
                                        className="helpMargin ubuntuFont"
                                    >
                                        Part: When the piece is a duet or
                                        ensemble a ‘part’ icon will appear, by
                                        clicking on this, you can choose which
                                        part you want to play. If you click
                                        ‘part 2’ or ‘part 3’ etc then the
                                        onscreen Musitude notation will change
                                        to that part.
                                    </Grid.Column>
                                </Grid>
                                <Grid divided>
                                    <Grid.Column width={4}>
                                        <Image
                                            middle
                                            aligned
                                            src={Piano}
                                            size="mini"
                                        />
                                    </Grid.Column>
                                    <Grid.Column
                                        width={12}
                                        className="helpMargin ubuntuFont"
                                    >
                                        Instrument selection: Here you can
                                        select your instrument.
                                    </Grid.Column>
                                </Grid>
                                <Grid divided>
                                    <Grid.Column width={4}>
                                        <Image src={pagingImg}/>
                                    </Grid.Column>
                                    <Grid.Column
                                        width={12}
                                        className="helpMargin ubuntuFont"
                                    >
                                        These dots indicate the pages of
                                        Musitude notation, you can use the enter
                                        key to turn to the next page and
                                        backspace for the previous page.
                                    </Grid.Column>
                                </Grid>
                            </Grid.Column>
                            <Grid.Column width={7} className="marginAround">
                                <Grid/>
                                <Modal.Header
                                    as="h2"
                                    className="ubuntuFont"
                                    style={{
                                        marginTop: '90px',
                                        marginBottom: '25px',
                                    }}
                                >
                                    How to play Musitude
                                </Modal.Header>
                                <p className="ubuntuFont">
                                    When you select a piece of music, Musitude
                                    will display it on the screen. You can then
                                    use your qwerty keyboard as your musical
                                    instrument. Each letter and number
                                    corresponds to a different note. So if you
                                    see the letter ‘A’ on screen you press the
                                    'A' key on your keyboard. If you see B,C,D
                                    etc the you press those keys one after the
                                    other. If you see a broken circle with no
                                    letters or numbers you count that as a
                                    ‘silent note’ - that means you count that
                                    beat but don’t play anything. You play each
                                    letter or number sequentially and hold down
                                    the key depending on how long the shell
                                    around the note is. One full circle shell is
                                    one beat. A shell stretching to two circles
                                    means you hold the key down for two beats.
                                    If you see two letters in a single circle
                                    shell then you play two notes, one after the
                                    other (not at the same time) in the space of
                                    one beat.
                                </p>
                                <p
                                    className="ubuntuFont"
                                    style={{
                                        marginTop: '50px',
                                    }}
                                >
                                    Find a piece of music that you already know
                                    the melody for then press 'play' to
                                    auto-play the piece and follow the notes
                                    with your eyes and you can see how the
                                    shells indicate the length/duration of the
                                    note. Then try to play along with the
                                    auto-play and see if your playing sounds the
                                    same. Finally, turn off auto-play and play
                                    the piece by yourself and see if it sounds
                                    right. Once you feel confident in playing a
                                    piece, try a simple duet such as 'Twinkle
                                    Twinkle' and have Musitude play part 2 while
                                    you play part one. Ideally, play a duet
                                    along side a friend on two qwerty keyboards.
                                </p>
                            </Grid.Column>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        )
    }
}

export default HelpModal
