import React, { Component } from 'react'

class Joiner extends Component {

    render() {
        return (
            <svg className="joinerDefault" viewBox="0 0 1.5 10">
                <rect y="2" width="1.5" height="0.25"/>
                <rect y="7.75" width="1.5" height="0.25"/>
            </svg>
        )
    }
}

export default Joiner
