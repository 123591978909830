import React, { Component } from 'react';

class LandingPage extends Component {
    render() {

        return (
            <div className="bootstrap-styles olympus-styles">
                <div className="main-header main-header-fullwidth mtudeBackground">
                    <div className="content-bg-wrap">
                        <div className="content-bg bg-landing" />
                    </div>
                    <div className="container">
                        <div className="row display-flex">
                            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 offset-xl-2 offset-lg-2">
                                <div className="landing-content">
                                    <iframe title="duet-greensleeves" width="100%" height="415" src="https://www.youtube.com/embed/PIh6eWvAAYI"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LandingPage;
