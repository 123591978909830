import React, { Component } from "react";
import { Grid, Menu, Segment } from "semantic-ui-react";
import { browserHistory } from "react-router";
import Navigation from "../../components/olympus/components/Navigation";
import Footer from "../../components/olympus/components/Footer";

class BrowseView extends Component {
    state = { activeItem: window.location.pathname };

    handleItemClick = (e, { value }) => {
        this.setState({ activeItem: value });
        browserHistory.push(value);
    };

    componentWillReceiveProps() {
        this.setState({ activeItem: window.location.pathname });
    }

    render() {
        const { activeItem } = this.state;

        return (
            <div>
                <Navigation bus={this.props.bus} />
                <Grid
                    columns={1}
                    padded={true}
                    style={{ paddingBottom: "30vh" }}
                >
                    <Grid.Column className="noVerticalPadding">
                        <Menu pointing secondary className="ubuntuFont">
                            <Menu.Item
                                name="Genres"
                                value="/browse"
                                active={activeItem === "/browse"}
                                onClick={this.handleItemClick}
                            />
                            <Menu.Item
                                name="Duets"
                                value="/browse/duets"
                                active={activeItem === "/browse/duets"}
                                onClick={this.handleItemClick}
                            />
                            <Menu.Item
                                name="Ensembles"
                                value="/browse/ensembles"
                                active={activeItem === "/browse/ensembles"}
                                onClick={this.handleItemClick}
                            />
                            <Menu.Item
                                name="List"
                                value="/browse/list"
                                active={activeItem === "/browse/list"}
                                onClick={this.handleItemClick}
                            />
                        </Menu>
                        <Segment>{this.props.children}</Segment>
                    </Grid.Column>
                </Grid>
                <Footer />
            </div>
        );
    }
}

export default BrowseView;
