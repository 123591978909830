import React, { Component } from 'react'
import { DEFAULTS } from '../config'

class OpenLeft extends Component {

    render() {
        return (
            <svg className="noteDefault" viewBox="0 0 10 10">
                <path
                    d="M5,.5H5c2.46,0,3.5,1.75,5,1.75V2C8.5,2,7.46,0,5,0H5A5,5,0,0,0,5,10H5c2.46,0,3.5-2,5-2V7.75c-1.5,0-2.54,1.74-5,1.74H5a4.5,4.5,0,0,1,0-9Z"/>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSize} x={DEFAULTS.textXMiddlePosition}
                      y={DEFAULTS.textYPosition}>{this.props.note}</text>

            </svg>
        )
    }
}

export default OpenLeft
