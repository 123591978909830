import React, { Component } from 'react';
import { Dimmer, Grid, Header, Image, Loader, Segment } from 'semantic-ui-react';
import DynamicNotationContainer from '../../components/notation/DynamicNotationContainer';
import { LoadingState } from '../../services/http';
import Instruments from '../../assets/notation/instruments.json';
import DemoPiece1 from '../../assets/notation/greensleeves';
import LoadingText from '../../assets/loading_text.png';
import howToPlay from '../../assets/howtoplay-compressor.png';
import { Event } from '../../services/Event';

class DemoPlayer extends Component {
    state = {
        hasRelatedArtists: undefined,
        loadingState: LoadingState.PENDING,
        notation: undefined,
        instruments: undefined,
        active: true
    };

    componentWillMount() {
        this.loadNotation(DemoPiece1);
    }

    loadNotation(piece) {
        this.setState({
            instruments: Instruments,
            notation: piece,
            loadingState: LoadingState.SUCCESS
        });
    }

    handleHide(bus) {
        bus.send(Event.EnablePlayer, {});
        this.setState({ active: false });
    }

    render() {
        const notation = (
            <DynamicNotationContainer
                demoplayer={true}
                bus={this.props.bus}
                notation={this.state.notation}
                instruments={this.state.instruments}
            />
        );
        const { active } = this.state;

        if (this.state.loadingState === LoadingState.PENDING) {
            return <LoadingNotation />;
        } else if (this.state.loadingState === LoadingState.FAILED) {
            return null;
        } else {
            return (
                <div className="demoplayer">
                    <Dimmer.Dimmable dimmed={active} className={'howtoplay'}>
                        <Dimmer onClick={this.handleHide.bind(this, this.props.bus)} inverted active={active}>
                            <Header
                                style={{
                                    cursor: 'pointer',
                                    marginTop: '-25px'
                                }}
                                as="h2"
                            >
                                <div className="instruction">
                                    <p>Use your Qwerty keys to play music.</p>
                                    <p className="continue">click to continue</p>
                                    <Image alt="Fingers and keyboard demonstrating how to play musitude" style={{ width: '20em' }} src={howToPlay} />
                                </div>
                            </Header>
                        </Dimmer>
                        {notation}
                    </Dimmer.Dimmable>
                </div>
            );
        }
    }
}

const LoadingNotation = () => {
    return (
        <Grid padded={true} columns={1} style={{ height: '100%' }}>
            <Grid.Column width={16}>
                <Segment padded="very">
                    <Dimmer active inverted>
                        <Loader size="large">Rendering Notation</Loader>
                    </Dimmer>
                    <Image src={LoadingText} />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default DemoPlayer;
