import React, { Component } from 'react'
import { DEFAULTS } from '../config'

class ThreeQuarterLeft extends Component {

    render() {
        return (
            <svg className="halfNoteDefault" viewBox="0 0 5 10">
                <polygon points="4.88 7.88 5 8 5 2 4.88 2.13 4.88 7.88"/><path d="M5,0A5,5,0,0,0,5,10H5V9.62A4.49,4.49,0,0,1,.63,5,4.5,4.5,0,0,1,5,.37V0Z"/>s
                <text textAnchor="middle" fontSize={DEFAULTS.fontSize} x="2.875"
                      y={DEFAULTS.textYPosition}>{this.props.note}</text>
            </svg>
        )
    }
}

export default ThreeQuarterLeft
