import React, { Component } from 'react'
import OpenShortHalfLeft from './OpenShortHalfLeft'
import Joiner from './Joiner'
import OpenHalfRight from './OpenHalfRight'
import ShortHalfLeft from './ShortHalfLeft'
import SustainClose from './SustainClose'
import Middle from './Middle'
import { ShapeDuration } from '../config'
import ShortHalfRight from './ShortHalfRight'

class OpenLeftSustain extends Component {

    static defaultProps = {
        oddStartPosition: false,
    }

    buildStart(oddStartPosition) {
        if (oddStartPosition) {
            return <ShortHalfRight note={this.props.note}/>
        }
    }

    /**
     * Build the middle for sustain shape.
     * The middle is calculated as the duration minus the beats rendered by start and end blocks.
     * @param duration
     * @returns {Array}
     */
    buildSustainMiddle(duration, oddStartPosition, cradlePosition) {
        const BEATS_PER_MIDDLE = ShapeDuration.MIDDLE
        let oddDuration = this.isOdd(duration)
        //continued sustains should not start with a joiner
        let skipJoin = true
        //calculate how many beats (middle notes) should be rendered
        let beatsRemaining = duration
        //remove beat for opening shape
        if (oddStartPosition) {
            beatsRemaining -= ShapeDuration.OPEN_SHORT_HALF_LEFT
        }
        //remove beats for closing shape
        if ((oddDuration && oddStartPosition) || (!oddDuration && !oddStartPosition)) {
            beatsRemaining -= ShapeDuration.SUSTAIN_CLOSE
        } else {
            beatsRemaining -= ShapeDuration.OPEN_SHORT_HALF_LEFT
        }
        let middle = []
        let noOfMiddleNotes = Math.round(beatsRemaining / BEATS_PER_MIDDLE)

        for (let i = 0; i < noOfMiddleNotes; i++) {
            if (cradlePosition === 0 && skipJoin) {
                skipJoin = false
            } else {
                middle.push(<Joiner key={'j-' + i.toString()}/>)
            }
            middle.push(<Middle key={'m-' + i.toString()}/>)
        }

        return middle
    }

    buildSustainEnd(duration, oddStartPosition) {
        let oddDuration = this.isOdd(duration)
        let end = []
        if ((oddDuration && oddStartPosition) || (!oddDuration && !oddStartPosition)) {
            end.push(<Joiner key='j-end'/>)
            end.push(<SustainClose className="withMargin" key='ohr-end'/>)
        } else {
            end.push(<Joiner key='j-end'/>)
            end.push(<ShortHalfLeft key='oshl-ends'/>)
        }
        return end
    }

    isOdd(num) {
        return num % 2
    }

    render() {
        let duration = this.props.duration
        const oddStartPosition = this.props.oddStartPosition
        const cradlePosition = this.props.cradlePosition

        if (duration === 1) {
            return (
                <OpenShortHalfLeft note={this.props.note}/>
            )
        } else if (duration === 2) {
            return (
                <span>
                    <OpenShortHalfLeft note={this.props.note}/>
                    <OpenHalfRight key='oshl-ends'/>
                </span>
            )
        }
        return (
            <span>
                {this.buildStart(oddStartPosition)}
                {this.buildSustainMiddle(duration, oddStartPosition, cradlePosition)}
                {this.buildSustainEnd(duration, oddStartPosition)}
            </span>
        )
    }
}

export default OpenLeftSustain
