import React from "react";
import Firebase from "firebase/app";
import "firebase/remote-config";
import "firebase/analytics";

export const useFlags = () => {
    return React.useContext(FlagsContext);
};

export const defaultFlags = {
    searchSmallScreens: {
        default: false,
    },
};

const firebaseConfigDev =
    "ewogICJhcGlLZXkiOiAiQUl6YVN5QjBCVTB3M0JRV0U2R21nTEVfOFUzalNCTG5RWXpzcW1zIiwKICAiYXV0aERvbWFpbiI6ICJtdXNpdHVkZS0yMzBmZC5maXJlYmFzZWFwcC5jb20iLAogICJkYXRhYmFzZVVSTCI6ICJodHRwczovL211c2l0dWRlLTIzMGZkLmZpcmViYXNlaW8uY29tIiwKICAicHJvamVjdElkIjogIm11c2l0dWRlLTIzMGZkIiwKICAic3RvcmFnZUJ1Y2tldCI6ICJtdXNpdHVkZS0yMzBmZC5hcHBzcG90LmNvbSIsCiAgIm1lc3NhZ2luZ1NlbmRlcklkIjogIjY4Njc2NDc3NjgzNyIsCiAgImFwcElkIjogIjE6Njg2NzY0Nzc2ODM3OndlYjo0ZGI5MjRiZjg2MGYwNGQxZjI3OGVjIiwKICAibWVhc3VyZW1lbnRJZCI6ICJHLVJNMEZYNFlEUVYiCn0=";

const firebaseConfigProd =
    "ewogICJhcGlLZXkiOiAiQUl6YVN5QW1uakZvTUoyc1JHYzNoUms0Y2xFWTIybGRaS3VCeWwwIiwKICAiYXV0aERvbWFpbiI6ICJtdXNpdHVkZS1wcm9kLmZpcmViYXNlYXBwLmNvbSIsCiAgImRhdGFiYXNlVVJMIjogImh0dHBzOi8vbXVzaXR1ZGUtcHJvZC5maXJlYmFzZWlvLmNvbSIsCiAgInByb2plY3RJZCI6ICJtdXNpdHVkZS1wcm9kIiwKICAic3RvcmFnZUJ1Y2tldCI6ICJtdXNpdHVkZS1wcm9kLmFwcHNwb3QuY29tIiwKICAibWVzc2FnaW5nU2VuZGVySWQiOiAiODgzODgzODEzMTk0IiwKICAiYXBwSWQiOiAiMTo4ODM4ODM4MTMxOTQ6d2ViOmM5NjcwOTVhODE3NDhkNDJkODc5YjMiLAogICJtZWFzdXJlbWVudElkIjogIkctRkNCWUhCVjBCSCIKfQ==";

const firebase = Firebase.initializeApp(
    window.location.host.endsWith("musitude.com")
        ? JSON.parse(atob(firebaseConfigProd))
        : JSON.parse(atob(firebaseConfigDev))
);
firebase.analytics();

const FlagsContext = React.createContext({});
const remoteConfig = firebase.remoteConfig();

export const FlagsProvider = ({ defaults, children }) => {
    const [flags, setFlags] = React.useState(defaults);

    React.useEffect(() => {
        remoteConfig.defaultConfig = defaults;

        remoteConfig
            .fetchAndActivate()
            .then((activated) => {
                if (!activated) console.log("not activated");
                return remoteConfig.getAll();
            })
            .then((remoteFlags) => {
                const newFlags = {
                    ...flags,
                };

                for (const [key, config] of Object.entries(remoteFlags)) {
                    newFlags[key] = JSON.parse(config.asString());
                }

                setFlags(newFlags);
            })
            .catch((error) => console.error(error));
    }, []);

    return (
        <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
    );
};
