import React, { Component } from 'react'

class OpenCradleLeft extends Component {


    render() {
        return (
            <svg className="cradleDefault" viewBox="0 0 19.92 5.76">
                <path
                    d="M0,0.48V0h1.575c0.813,0,3.969,5.037,4.609,5.037H19.92v0.48c0,0-13.205,0-13.892,0S2.2,0.465,1.356,0.465S0,0.48,0,0.48z"
                />
            </svg>
        )
    }
}

export default OpenCradleLeft
