import React, { Component, useState, useEffect } from "react";
import { browserHistory } from "react-router";
import concat from "lodash/concat";
import { Image } from "semantic-ui-react";
import ReactImageFallback from "react-image-fallback";
import OutsideAlerter from "../../OutsideAlerter";
import logo from "../../../assets/Musitude-Icon-White.svg";
import fallbackImg from "./../../../styles/olympus/app/img/avatar41-sm.jpg";
import { Event } from "../../../services/Event";
import HelpModal from "../../notation/HelpModal";
import { gql, useLazyQuery } from "@apollo/client";
import useDebounce from "../../../common/debounce";
import { uuid } from "../../../common/random";
import ReactGA from "react-ga";

const DefaultArtistImage = "https://cdn.musitude.com/artist/default.jpg";

export default class Navigation extends Component {
    state = {
        avatar: fallbackImg,
        profile: undefined,
    };

    render() {
        const { avatar, profile } = this.state;

        return (
            <div className="bootstrap-styles">
                <div className="olympus-styles">
                    <Header
                        bus={this.props.bus}
                        avatar={avatar}
                        profile={profile}
                    />
                    <ResponsiveHeader
                        bus={this.props.bus}
                        avatar={avatar}
                        profile={profile}
                    />
                </div>
            </div>
        );
    }
}

const SearchResultType = {
    SONG: "Songs",
    ARTIST: "Artists",
    TOP_RESULT: "Top Result",
};

const Category = (props) => <div className="">{props.name}</div>;

const SearchResultItem = (props) => {
    return (
        <div
            className="inline-items pt-2 pb-2"
            onClick={() => {
                props.onSelect();
                browserHistory.push(props.titleLink);
            }}
        >
            <div className="playlist-thumb">
                <ReactImageFallback
                    src={props.imageHref}
                    fallbackImage={fallbackImg}
                    alt="imageItem"
                    style={{
                        height: "34px",
                        width: "34px",
                    }}
                />
            </div>
            <div className="notification-event">
                <span className="h6 notification-friend">{props.title}</span>
                <span className="chat-message-item">{props.subTitle}</span>
            </div>
        </div>
    );
};

const query = gql`
    query Search($v: String!) {
        search(q: $v) {
            topResult {
                __typename
                ... on ArtistSearchResult {
                    id
                    name
                    imageHref
                }
                ... on NotationSearchResult {
                    id
                    genre
                    title
                    artist {
                        id
                        name
                        imageHref
                    }
                }
            }
            artists {
                id
                name
                imageHref
            }
            notation {
                id
                title
                genre
                artist {
                    id
                    name
                    imageHref
                }
            }
        }
    }
`;

const Search = (props) => {
    const [search, { loading, data }] = useLazyQuery(query);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(() => {
        if (debouncedSearchTerm) {
            search({ variables: { v: debouncedSearchTerm } });
            ReactGA.event({
                category: "User",
                action: "Performed a search",
            });
        } else {
            search({ variables: { v: "" } });
        }
    }, [debouncedSearchTerm]);

    const resetComponent = () => {
        search({ variables: { v: "" } });
    };

    const renderResults = ({ search }) => {
        let topResult;

        if (search.topResult) {
            if (search.topResult.__typename === "ArtistSearchResult") {
                topResult = (
                    <SearchResultItem
                        k={uuid()}
                        imageHref={
                            search.topResult.imageHref
                                ? search.topResult.imageHref
                                : DefaultArtistImage
                        }
                        onSelect={resetComponent}
                        type={SearchResultType.ARTIST}
                        title={search.topResult.name}
                        artistId={search.topResult.id}
                        titleLink={`/browse/list?artist=${search.topResult.id}`}
                    />
                );
            } else {
                topResult = (
                    <SearchResultItem
                        onSelect={resetComponent}
                        key={uuid()}
                        imageHref={
                            search.topResult.artist.imageHref
                                ? search.topResult.artist.imageHref
                                : DefaultArtistImage
                        }
                        type={SearchResultType.SONG}
                        title={search.topResult.title}
                        subTitle={search.topResult.artist.name}
                        titleLink={`/player/${search.topResult.id}`}
                        artistId={search.topResult.artist.id}
                    />
                );
            }
        }

        const songs = search.notation.map((result, i) => {
            return (
                <SearchResultItem
                    onSelect={resetComponent}
                    key={i}
                    imageHref={
                        result.artist.imageHref
                            ? result.artist.imageHref
                            : DefaultArtistImage
                    }
                    type={SearchResultType.SONG}
                    title={result.title}
                    subTitle={result.artist.name}
                    titleLink={`/player/${result.id}`}
                    artistId={result.artist.id}
                />
            );
        });

        const artists = search.artists.map((result, i) => {
            return (
                <SearchResultItem
                    k={i + 10}
                    imageHref={
                        result.imageHref ? result.imageHref : DefaultArtistImage
                    }
                    onSelect={resetComponent}
                    type={SearchResultType.ARTIST}
                    title={result.name}
                    artistId={result.id}
                    titleLink={`/browse/list?artist=${result.id}`}
                />
            );
        });

        const topResultSection = topResult
            ? [
                  <Category key={uuid()} name={SearchResultType.TOP_RESULT} />,
                  topResult,
              ]
            : [];
        const songsSection =
            songs.length > 0
                ? [
                      <Category key={uuid()} name={SearchResultType.SONG} />,
                      ...songs,
                  ]
                : [];
        const artistsSection =
            artists.length > 0
                ? [
                      <Category key={101} name={SearchResultType.ARTIST} />,
                      ...artists,
                  ]
                : [];
        return concat([topResultSection, songsSection, artistsSection]);
    };

    return (
        <OutsideAlerter onOutside={resetComponent}>
            <div className="search-bar w-search notification-list friend-requests">
                <div className="form-group with-button">
                    <input
                        className="form-control js-user-search"
                        onClick={() => props.bus.send(Event.MutePlayer, {})}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search here for songs and artists..."
                        type="text"
                    />
                    <button>
                        <svg className={`olymp-magnifying-glass-icon`}>
                            <use
                                xlinkHref={`/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon`}
                            />
                        </svg>
                    </button>
                    <div
                        className="selectize-dropdown multi form-control js-user-search"
                        style={{
                            display: "block",
                            width: "300px",
                            top: "70px",
                            left: "0px",
                            visibility: "visible",
                        }}
                    >
                        <div
                            key={uuid()}
                            className="selectize-dropdown-content"
                        >
                            {loading ? null : data && renderResults(data)}
                        </div>
                    </div>
                </div>
            </div>
        </OutsideAlerter>
    );
};

const Header = (props) => {
    return (
        <div>
            <header className="header" id="site-header">
                <div className="page-title" style={{ padding: "15px" }}>
                    <img
                        className="cursorHover"
                        style={{ width: "40px" }}
                        src={logo}
                        alt=""
                        onClick={() => browserHistory.push("/")}
                    />
                </div>
                <div className="header-content-wrapper">
                    <Search bus={props.bus} />
                    <span
                        onClick={() => browserHistory.push("/browse")}
                        className="guide-text link-find-friend"
                    >
                        Browse
                    </span>
                    <a
                        href="https://discord.com/invite/FpAnyTc"
                        className="link-find-friend"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Discord
                    </a>
                    <HelpModal
                        trigger={
                            <span className="guide-text link-find-friend">
                                How to play
                            </span>
                        }
                    />
                    {props.profile ? (
                        <Profile
                            avatar={props.avatar}
                            profile={props.profile}
                        />
                    ) : null}
                </div>
            </header>
            <div className="header-spacer" style={{ height: "75px" }} />
        </div>
    );
};

const Profile = (props) => {
    return (
        <div className="control-block">
            <div className="author-page author vcard inline-items more">
                <div className="author-thumb">
                    <Image
                        fluid
                        role="presentation"
                        src={props.avatar ? props.avatar : DefaultArtistImage}
                        className="avatar cursorHover"
                    />
                    <span className="icon-status online" />
                    <div className="more-dropdown more-with-triangle">
                        <div className="mCustomScrollbar" data-mcs-theme="dark">
                            <div className="ui-block-title ui-block-title-small">
                                <h6 className="title">Your Account</h6>
                            </div>
                            <ul className="account-settings">
                                <li>
                                    <a href="/profile">
                                        <svg className="olymp-menu-icon">
                                            <use xlinkHref="/svg-icons/sprites/icons.svg#olymp-menu-icon" />
                                        </svg>
                                        <span>Profile</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://slack.musitude.com">
                                        <svg className="olymp-happy-faces-icon">
                                            <use xlinkHref="/svg-icons/sprites/icons.svg#olymp-happy-faces-icon" />
                                        </svg>
                                        <span>npCommunity</span>
                                    </a>
                                </li>
                            </ul>
                            <div className="ui-block-title ui-block-title-small">
                                <h6 className="title">About</h6>
                            </div>
                            <ul>
                                <li>
                                    <a href="/terms">
                                        <span>Terms of Use</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/privacy">
                                        <span>Privacy Policy</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <a href="/profile" className="author-name fn">
                    <div className="author-title">
                        {props.profile.fullName}
                        <svg className="olymp-dropdown-arrow-icon">
                            <use xlinkHref="/svg-icons/sprites/icons.svg#olymp-dropdown-arrow-icon" />
                        </svg>
                    </div>
                    <span className="author-subtitle">
                        {props.profile.username}
                    </span>
                </a>
            </div>
        </div>
    );
};

const ResponsiveHeader = (props) => {
    return (
        <header
            className="header header-responsive pr-1 pl-1"
            id="site-header-responsive"
        >
            <ul className="nav nav-tabs mobile-app-tabs" role="tablist">
                <li className="nav-item">
                    <a
                        className="nav-link"
                        data-toggle="tab"
                        href="/"
                        role="tab"
                    >
                        <img
                            className="cursorHover"
                            style={{ width: "40px" }}
                            src={logo}
                            alt=""
                            onClick={() => browserHistory.push("/")}
                        />
                    </a>
                </li>

                <li className="nav-item">
                    <a
                        className="nav-link"
                        data-toggle="tab"
                        href="/browse"
                        role="tab"
                    >
                        <div className="control-icon has-items">
                            <svg className="olymp-magnifying-glass-icon">
                                <use xlinkHref="/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon" />
                            </svg>
                        </div>
                    </a>
                </li>

                <li className="nav-item">
                    <a
                        className="nav-link"
                        data-toggle="tab"
                        href="/profile"
                        role="tab"
                    >
                        <div className="author-thumb">
                            <Image
                                fluid
                                role="presentation"
                                src={props.avatar}
                                className="avatar cursorHover"
                            />
                            <span className="icon-status online" />
                        </div>
                    </a>
                </li>
            </ul>
        </header>
    );
};
