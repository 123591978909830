import React, { Component } from 'react'
import { DEFAULTS } from '../config'

class WholeShifted extends Component {

    render() {
        return (
            <svg className="noteWithMarginDefault" viewBox="0 0 11.5 10">
                <path
                    d="M5,7.74c-1.5,0-2.54,1.75-5,1.75V10c2.46,0,3.5-2,5-2H6.5C8,8,9,10,11.5,10v-.5C9,9.49,8,7.74,6.5,7.74Z"/>
                <path
                    d="M6.5,2H5C3.5,2,2.46,0,0,0v.5c2.46,0,3.5,1.75,5,1.75H6.5C8,2.24,9,.49,11.5.49V0C9,0,8,2,6.5,2Z"/>
                <text fontSize={DEFAULTS.fontSize} x="0.2" y={DEFAULTS.textYPosition}>{this.props.note}</text>
            </svg>
        )
    }
}

export default WholeShifted
