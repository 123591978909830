import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    Card,
    Dimmer,
    Grid,
    Header,
    Icon,
    Label,
    Loader,
    Segment,
} from "semantic-ui-react";
import isEmpty from "lodash/isEmpty";
import each from "lodash/each";
import NotationHeader from "../header/NotationHeader";
import Measure from "react-measure";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LoadingState } from "../../services/http";
import AdBlockDetect from "react-ad-block-detect";
import Transition from "react-transition-group/Transition";
import { RendererContainer } from "./../../components/Renderer/Renderer.container";
import { clearTrackAnimation, initialiseAnimations } from "./NotationAnimation";
import { AppstoreLinks } from "./../../components/notation/AppstoreLinks";
import { isMobile } from "react-device-detect";

const ANIMATION_DONE_EVENT = "animation::done";

let animations = [];

class DynamicNotationContainer extends Component {
    static propTypes = {
        instruments: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                displayName: PropTypes.string,
            })
        ).isRequired,
        notation: PropTypes.shape({}),
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedVariationIndex: 0,
            selectedInstrumentIndex: undefined,
            selectedRange: undefined,
            selectedTempo: undefined,
            notationContainerHeight: undefined,
            isInstrumentLoading: false,
            hasInstrumentLoadingFailed: false,
        };
    }

    componentWillMount() {
        this.setState({
            notation: this.props.notation,
        });
    }

    componentWillReceiveProps(props) {
        clearTrackAnimation(animations);
        const songHasChanged = this.props.notation.id !== props.notation.id;
        if (songHasChanged) {
            this.setState({
                notation: props.notation,
                selectedVariationIndex: 0,
            });
        }
    }

    /**
     * Function passed to the NotationHeader as a prop
     * @param event
     * @param data
     */
    variationChanged(event, data) {
        this.setState({
            selectedVariationIndex: data.value,
            selectedInstrumentIndex: undefined,
            selectedRange: undefined,
        });
    }
    /**
     * Function passed to the NotationHeader as a prop
     * @param event
     * @param data
     */
    tempoChanged(event, data) {
        this.setState({
            selectedTempo: data.value,
        });
    }
    notifyInstrumentLoadingState = (state) => {
        this.setState({
            isInstrumentLoading: state === LoadingState.PENDING,
            hasInstrumentLoadingFailed: state === LoadingState.FAILED,
        });
    };

    pauseAnimation() {
        if (animations) {
            each(animations, (trackAnimation) => {
                trackAnimation.pause();
            });
        }
    }

    playAnimation(selectedTempo, variation) {
        animations = initialiseAnimations(
            animations,
            this.state.gridContainer,
            selectedTempo,
            variation
        );
    }

    stopAnimation() {
        clearTrackAnimation(animations);
    }

    restartAnimation() {
        if (animations) {
            each(animations, (trackAnimation) => {
                trackAnimation.restart();
            });
        }
    }

    render() {
        const {
            selectedVariationIndex,
            notation,
            isInstrumentLoading,
            hasInstrumentLoadingFailed,
            dimensions,
        } = this.state;
        const { instruments } = this.props;

        if (isEmpty(notation.variations)) {
            return <NoVariations notation={notation} />;
        }

        let variation = notation.variations[selectedVariationIndex];

        //render notation container for demoplayer
        const demoplayer = this.props.demoplayer;
        return (
            <Dimmer.Dimmable
                as={Segment}
                dimmed={isInstrumentLoading}
                style={{
                    padding: "0",
                    border: "1px solid #e6ecf5",
                    borderRadius: "5px",
                    boxShadow: "unset",
                }}
            >
                <Dimmer active={isInstrumentLoading} inverted>
                    <Loader>Loading Instruments...</Loader>
                </Dimmer>
                <Card
                    className="ubuntuFont"
                    style={{
                        borderRadius: "5px",
                        width: "100%",
                        boxShadow: "none",
                        marginTop: "0",
                    }}
                >
                    <Card.Content style={{ padding: "0px" }}>
                        {hasInstrumentLoadingFailed ? (
                            <AdBlockDetect>
                                <Label basic color="red">
                                    Sound files may be blocked by your
                                    adblocker, please disable it for this site.
                                    We don't display adverts!
                                </Label>
                            </AdBlockDetect>
                        ) : (
                            ""
                        )}
                        <NotationHeader
                            notifyInstrumentLoadingState={
                                this.notifyInstrumentLoadingState
                            }
                            bus={this.props.bus}
                            instruments={instruments}
                            variation={variation}
                            notation={notation}
                            demoplayer={this.props.demoplayer}
                            selectedTempo={this.state.selectedTempo}
                            tempoChanged={this.tempoChanged.bind(this)}
                            variationChanged={this.variationChanged.bind(this)}
                            pauseAnimation={this.pauseAnimation.bind(this)}
                            playAnimation={this.playAnimation.bind(this)}
                            restartAnimation={this.restartAnimation.bind(this)}
                            stopAnimation={this.stopAnimation.bind(this)}
                        />
                    </Card.Content>

                    <Card.Content>
                        <Grid columns={3}>
                            <Grid.Row>
                                <Grid.Column className="textLeft">
                                    {" "}
                                    <a
                                        style={{ color: "#515365" }}
                                        href={`/browse/list?artist=${notation.artist.id}`}
                                    >
                                        {notation.artist.name}{" "}
                                    </a>
                                </Grid.Column>
                                <Grid.Column
                                    className="textCenter"
                                    style={{ textDecoration: "underline" }}
                                >
                                    <h2
                                        className="ubuntuFont"
                                        style={{ fontSize: "1.6rem" }}
                                    >
                                        {notation.title}
                                    </h2>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Content>
                    <Card.Content style={{ borderTop: "0px" }}>
                        <Measure
                            bounds
                            onResize={(contentRect) => {
                                this.setState({
                                    dimensions: contentRect.bounds,
                                });
                            }}
                        >
                            {({ measureRef }) => (
                                <div
                                    ref={measureRef}
                                    className={
                                        !isMobile ? "notationContainer" : ""
                                    }
                                    style={
                                        demoplayer
                                            ? {
                                                  height: "100%",
                                                  minHeight: "300px",
                                              }
                                            : { paddingBottom: "50px" }
                                    }
                                >
                                    <Transition
                                        unmountOnExit
                                        appear
                                        addEndListener={(node, done) =>
                                            node.addEventListener(
                                                ANIMATION_DONE_EVENT,
                                                done
                                            )
                                        }
                                        onEnter={(node, done) => {
                                            this.setState({
                                                gridContainer: node,
                                            });
                                        }}
                                        in={true}
                                        timeout={1}
                                    >
                                        <div>
                                            {dimensions ? (
                                                isMobile ? (
                                                    <AppstoreLinks />
                                                ) : (
                                                    <RendererContainer
                                                        variation={variation}
                                                        height={
                                                            dimensions.height
                                                        }
                                                        width={
                                                            dimensions.width +
                                                            10
                                                        }
                                                    />
                                                )
                                            ) : (
                                                <div />
                                            )}
                                        </div>
                                    </Transition>
                                </div>
                            )}
                        </Measure>
                    </Card.Content>
                </Card>
            </Dimmer.Dimmable>
        );
    }
}

const NoVariations = (props) => (
    <Segment padded={true}>
        <Header as="h4" icon textAlign="center">
            <Icon name="mute" circular />
            <Header.Content>
                We are in the process of adding notation to{" "}
                <b style={{ textDecoration: "underline" }}>
                    {props.notation.title}
                </b>
                .
                <br />
                Please come back later.
            </Header.Content>
        </Header>
    </Segment>
);

export default DynamicNotationContainer;
