import React, { Component } from 'react'
import { DEFAULTS } from '../config'

class QuarterLeft extends Component {

    render() {
        return (
            <svg className="halfNoteDefault" viewBox="0 0 5 10">
                <path
                    d="M5,0H5A5,5,0,0,0,5,10H5V9.62a4.18,4.18,0,0,1-1.51-.28L5,8V2L3.49.66A4.18,4.18,0,0,1,5,.37ZM4.87,2.25v5.5L3.16,9.19A4.64,4.64,0,0,1,.63,5,4.67,4.67,0,0,1,3.16.8Z"/>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSize} x="2.5"
                      y={DEFAULTS.textYPosition}>{this.props.note}</text>

            </svg>
        )
    }
}

export default QuarterLeft
