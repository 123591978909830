import React, { Component } from 'react'
import { DEFAULTS } from '../config'

class Quad extends Component {

    render() {
        return (
            <svg className="noteDefault" viewBox="0 0 20 20">
                <path
                    d="m 10.021187,0.0211864 a 10,10 0 1 0 10,10.0000006 10,10 0 0 0 -10,-10.0000006 z m 9.49,9.7500003 h -9.25 V 0.77118639 a 9.68,9.68 0 0 1 9.24,9.00000031 z M 9.7711865,0.77118639 V 9.7711867 H 1.0211864 A 9.23,9.23 0 0 1 9.7611865,0.77118639 Z M 1.0211864,10.271187 h 8.7400001 v 9 a 9.23,9.23 0 0 1 -8.7400001,-9 z m 9.2400006,9 v -9 h 9.24 a 9.68,9.68 0 0 1 -9.24,9 z"/>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSizeQuad} x="6" y="8.8">{this.props.note1}</text>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSizeQuad} x="14" y="8.8">{this.props.note2}</text>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSizeQuad} x="6" y="16.6">{this.props.note3}</text>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSizeQuad} x="14" y="16.6">{this.props.note4}</text>
            </svg>
        )
    }
}

export default Quad
