import React from "react";
import ReactDOM from "react-dom";
import Router from "./Router";
import { Helmet } from "react-helmet";
import { browserHistory } from "react-router";
import "./styles/index.scss";
import { StoreProvider } from "./store";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "@apollo/client";
import { client } from "./services/graphql.js";
import { defaultFlags, FlagsProvider } from "./firebase";

ReactDOM.render(
    <FlagsProvider defaults={defaultFlags}>
        <ApolloProvider client={client}>
            <Helmet defaultTitle="Musitude - you can play" />
            <StoreProvider>
                <Router history={browserHistory} />
            </StoreProvider>
        </ApolloProvider>
    </FlagsProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
