import React from "react";
import { ordered } from "../../common/util";
import { Dimmer, Grid, Image, List, Loader, Segment } from "semantic-ui-react";
import { Link } from "react-router";
import Loading from "../../assets/loading_text.png";
import map from "lodash/map";
import groupBy from "lodash/groupBy";
import { gql, useQuery } from "@apollo/client";

export const DuetsView = NewSongList(gql`
    query {
        notation(group: DUET) {
            id
            title
            artist {
                name
            }
        }
    }
`);

export const EnsemblesView = NewSongList(gql`
    query {
        notation(group: ENSEMBLE) {
            id
            title
            artist {
                name
            }
        }
    }
`);

export function NewSongList(query) {
    return function () {
        const { loading, error, data } = useQuery(query);

        if (loading) {
            return (
                <Segment basic>
                    <Dimmer active inverted>
                        <Loader size="large">Loading Notation</Loader>
                    </Dimmer>
                    <Image src={Loading} />
                </Segment>
            );
        } else if (error) {
            return null; // TODO
        } else {
            const notationGroupedByArtist = ordered(
                groupBy(data.notation, "artist.name")
            );

            const artistComponents = map(
                notationGroupedByArtist,
                (songs, artist) => {
                    const songComponents = songs.map((song, i) => {
                        return (
                            <List.Item key={i}>
                                <Link to={`/player/${song.id}`}>
                                    {song.title}
                                </Link>
                            </List.Item>
                        );
                    });

                    return (
                        <List.Item key={artist}>
                            <b>{artist}</b>
                            <List.List>{songComponents}</List.List>
                        </List.Item>
                    );
                }
            );

            return (
                <Grid
                    centered={true}
                    padded={true}
                    textAlign="left"
                    className="ubuntuFont"
                >
                    <Grid.Row>
                        <Grid.Column>
                            <div className="listContainer">
                                <List>{artistComponents}</List>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }
    };
}
