import React, {Component} from "react";
import PropTypes from "prop-types";
import Soundfont from "soundfont-player";
import {Icon, Image, Menu} from "semantic-ui-react";
import {midi} from "note-parser";
import isUndefined from "lodash/isUndefined";
import flatten from "lodash/flatten";
import filter from "lodash/filter";
import each from "lodash/each";
import "@mohayonao/web-audio-api-shim";
import {LoadingState} from "../../services/http";
import {generateCountInClicks} from "./countInClickGenerator";
import {AUTOPLAY_GAIN, GAIN} from "./soundPlayerConfig";
import {Event} from "../../services/Event";
import SoundOnIcon from "../../assets/icons/player/sound.svg";
import SoundOffIcon from "../../assets/icons/player/sound-off.svg";
import {gql} from "@apollo/client";
import {client} from "../../services/graphql";

function fetchVariationQuery(id) {
    return gql`
        query {
            notation(id: "${id}") {
                variations {
                    id
                    notes {
                        duration
                        note
                    }
                }
            }
        }
    `;
}

const audioContextCheck = () => {
    if (typeof window.AudioContext !== "undefined") {
        return new window.AudioContext();
    } else {
        return undefined;
    }
};

const audioContext = audioContextCheck();

const rangeToOctaveOffset = (range) => {
    switch (range) {
        case 1:
            return -2;
        case 2:
            return -1;
        case 3:
            return 0;
        case 4:
            return 1;
        case 5:
            return 2;
        default:
            return 0;
    }
};

class SoundPlayer extends Component {
    static propTypes = {
        instrument: PropTypes.object.isRequired,
        variation: PropTypes.object,
        variations: PropTypes.array,
        notationId: PropTypes.string,
        notifyInstrumentLoadingState: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            playerIsOn: false,
            playbackIsOn: false,
            status: "Loading piano",
            noteMap: {},
            player: undefined,
            metronomePlayer: undefined,
            keyUpListener: undefined,
            keyDownListener: undefined,
            keyAllowed: {},
            playbackHandlers: [],
            players: {},
            audioResumed: false,
        };

        this.disablePianoPlayer = this.disablePianoPlayer.bind(this);
        this.initializePlayer = this.initializePlayer.bind(this);
        this.enablePianoPlayer = this.enablePianoPlayer.bind(this);
        this.getMidiNoteForKey = this.getMidiNoteForKey.bind(this);
        this.downListener = this.downListener.bind(this);
        this.upListener = this.upListener.bind(this);
        this.mapMultiCharacterNotes = this.mapMultiCharacterNotes.bind(this);
        this.playNote = this.playNote.bind(this);
        this.keydownPlayNote.bind(this);
        this.playCountIn = this.playCountIn.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        // check to see if this method is invoked because the instrument has changed.
        // If so, restart the player with the new instrument
        if (this.props.instrument.name !== nextProps.instrument.name) {
            this.disablePianoPlayer();
            setTimeout(() => {
                this.initializePlayer();
                this.enablePianoPlayer();
            }, 100);
        }

        // if the variation changed, reload the key mappings as the range might be different
        if (this.props.variation.range !== nextProps.variation.range) {
            this.loadKeyMappings(nextProps.variation.range);
        }
    }

    componentWillUnmount() {
        if (this.state.playerIsOn) {
            this.disablePianoPlayer();
        }
        this.props.onRef(undefined);
        this.stop();
    }

    initializePlayer() {
        if (audioContext) {
            const {instrument} = this.props;
            this.props.notifyInstrumentLoadingState(LoadingState.PENDING);
            Promise.all([
                Soundfont.instrument(audioContext, instrument.soundFont, {}),
                Soundfont.instrument(audioContext, "woodblock", {
                    soundfont: `/assets/soundfont/woodblock`,
                }),
            ])
                .then((responses) => {
                    const instrument = responses[0];
                    const clickInstrument = responses[1];
                    let players = this.state.players;
                    players[instrument.name] = instrument;
                    this.setState(
                        {
                            player: instrument,
                            players: players,
                            metronomePlayer: clickInstrument,
                        },
                        () => {
                            this.state.player.stop();
                            this.state.metronomePlayer.stop();
                        }
                    );
                    this.enablePianoPlayer();
                    this.props.notifyInstrumentLoadingState(
                        LoadingState.SUCCESS
                    );
                })
                .catch((err) => {
                    console.log(err);
                    this.props.notifyInstrumentLoadingState(
                        LoadingState.FAILED
                    );
                    throw err;
                });
        }
    }

    componentWillMount() {
        const variation = this.props.variation;
        this.props.onRef(this);
        this.initializePlayer();
        this.loadKeyMappings(variation.range);
    }

    componentDidMount() {
        this.stop = this.props.bus.take(Event.MutePlayer, () => {
            this.disablePianoPlayer();
        });
        this.props.bus.take(Event.EnablePlayer, () => {
            this.enablePianoPlayer();
        });
    }

    /**
     * Loads the key mappings, converts to scientific notation format and maps to midi keys for
     * playback See https://en.wikipedia.org/wiki/Scientific_pitch_notation
     */
    loadKeyMappings(range = 3) {
        const octaveOffset = rangeToOctaveOffset(range);
        this.setState({keyMappings: require("./keyMappings.json")}, () => {
            const notes = {};
            each(this.state.keyMappings, (value, key) => {
                const scientificNotation =
                    value.note + (value.octave + octaveOffset);
                notes[key] = midi(scientificNotation);
            });
            this.setState({notes});
        });
    }

    upListener(event) {
        // bind escape to turn off player
        if (event.keyCode === 27) {
            this.disablePianoPlayer();
            return;
        }

        const playTimeout = 0;
        if (event.defaultPrevented) {
            return; // Do nothing if the event was already processed
        }
        let keyAllowed = Object.assign({}, this.state.keyAllowed);
        let playNote = keyAllowed[event.which];

        if (typeof playNote !== "undefined") {
            playNote.stop(audioContext.currentTime + playTimeout);
        }
        //remove note
        delete keyAllowed[event.which];
        this.setState({keyAllowed: keyAllowed});

        // Cancel the default action to avoid it being handled twice
        event.preventDefault();
    }

    downListener(event) {
        if (event.defaultPrevented) {
            return; // Do nothing if the event was already processed
        }

        if (this.state.keyAllowed[event.which] !== undefined) {
            return;
        }

        const midiNote = this.getMidiNoteForKey(event);

        if (midiNote) {
            let keyAllowed = Object.assign({}, this.state.keyAllowed);
            if (this.state.audioResumed) {
                this.keydownPlayNote(midiNote, keyAllowed, event);
            } else {
                audioContext.resume().then(() => {
                    this.keydownPlayNote(midiNote, keyAllowed, event);
                });
            }
        }
        // Cancel the default action to avoid it being handled twice
        event.preventDefault();
    }

    keydownPlayNote(midiNote, keyAllowed, event) {
        let playNote = this.playNote(midiNote);
        keyAllowed[event.which] = playNote;
        this.setState({
            audioResumed: true,
            keyAllowed: keyAllowed,
        });
    }

    getMidiNoteForKey(e) {
        const keyCode = e.keyCode ? e.keyCode : e.which;
        const keyboardCharacter = String.fromCharCode(keyCode);
        return this.state.notes[keyboardCharacter];
    }

    disablePianoPlayer() {
        try {
            if (this.state.player) {
                this.state.player.stop();
            }
        } catch (e) {
            console.error(e);
        }

        if (this.state.playbackHandlers) {
            this.state.playbackHandlers.forEach((handler) => {
                clearTimeout(handler);
            });
        }
        this.setState({
            playbackIsOn: false,
            playerIsOn: false,
        });
        window.removeEventListener("keyup", this.upListener);
        window.removeEventListener("keydown", this.downListener);
    }

    enablePianoPlayer() {
        this.setState({playerIsOn: true});

        if (!isUndefined(this.state.player)) {
            this.state.player.start();
        }
        window.addEventListener("keyup", this.upListener);
        window.addEventListener("keydown", this.downListener);
    }

    /**
     * support for triplets and quads
     */

    mapMultiCharacterNotes(characters, octaveOffset, duration) {
        function parseNote(note, octaveOffset) {
            return note !== null
                ? midi(note.note + (octaveOffset + note.octave))
                : null;
        }

        return [...characters].map((character) => {
            const mapping = this.state.keyMappings[character];
            const midiNote = parseNote(mapping, octaveOffset);
            return {
                duration: duration / characters.length,
                note: midiNote,
                gain: GAIN,
            };
        });
    }

    startPlayback(variations, withDesignatedInstrument) {
        function parseNote(note, octaveOffset) {
            return note !== null
                ? midi(note.note + (octaveOffset + note.octave))
                : null;
        }

        this.setState({
            playbackIsOn: true,
            playerIsOn: true,
        });

        // create the player if it's off
        this.enablePianoPlayer();
        const playbackHandlers = [];
        this.preloadInstrumentPlayers(variations).then(
            () => {
                variations.forEach((variation, index) => {
                    if (variation) {
                        client.query({
                            query: fetchVariationQuery(this.props.notationId)
                        })
                            .then((response) => {
                                const notes = response.data.notation[0].variations.filter(v => v.id === variation.id).pop().notes;
                                const playSequence = flatten(
                                    notes.map((variationItem) => {
                                        const octaveOffset = rangeToOctaveOffset(
                                            variation.range
                                        );

                                        // handle triplets and quads
                                        if (variationItem.note.length >= 3) {
                                            return this.mapMultiCharacterNotes(
                                                variationItem.note,
                                                octaveOffset,
                                                variationItem.duration
                                            );
                                        } else {
                                            const mapping = this.state.keyMappings[
                                                variationItem.note
                                                ];
                                            const midiNote = parseNote(
                                                mapping,
                                                octaveOffset
                                            );
                                            return {
                                                duration: variationItem.duration,
                                                note: midiNote,
                                                gain: GAIN,
                                            };
                                        }
                                    })
                                );

                                // play through the notes with an offset. Store the handlers so we
                                // can delete them later if the user clicks 'STOP'
                                let offset = 0;
                                const countInClicks = generateCountInClicks(
                                    parseInt(variation.signature, 10)
                                );
                                const notesToPlay = [
                                    ...countInClicks,
                                    ...playSequence,
                                ];
                                const players = this.state.players;
                                const tempo = this.props.tempo || variation.tempo;
                                notesToPlay.forEach((item) => {
                                    const handler = setTimeout(() => {
                                        const duration = (tempo / 60) * 2;
                                        if (item.isClick) {
                                            if (index === 0) {
                                                // prevent clicks for variations played at same time
                                                this.state.metronomePlayer.play(
                                                    item.note,
                                                    audioContext.currentTime,
                                                    {
                                                        gain: GAIN,
                                                        duration:
                                                            item.duration /
                                                            duration,
                                                    }
                                                );
                                            }
                                        } else {
                                            let player = withDesignatedInstrument
                                                ? players[variation.instrument.name]
                                                : this.state.player;
                                            player.play(
                                                item.note,
                                                audioContext.currentTime,
                                                {
                                                    gain: AUTOPLAY_GAIN,
                                                    loop: false,
                                                    duration:
                                                        item.duration / duration,
                                                }
                                            );
                                        }
                                    }, offset);
                                    playbackHandlers.push(handler);
                                    offset += item.duration * 500 * (60 / tempo);
                                });

                                // show stop button when finished
                                setTimeout(() => {
                                    this.props.bus.send(Event.PlaybackFinished, {});
                                }, offset);
                            });
                    }
                });

                if (this.props.trackerIsOn) {
                    this.props.playAnimation(
                        this.props.tempo || this.props.variation.tempo,
                        this.props.variation
                    );
                }
                this.setState({playbackHandlers: playbackHandlers});
            },
            (error) => {
                console.error("rejected: " + error);
            }
        );
    }

    preloadInstrumentPlayers(variations) {
        return new Promise((resolve) => {
            const players = this.state.players;
            let promises = [];
            let unloadedInstruments = filter(variations, (item) => {
                return isUndefined(players[item.instrument.name]);
            });
            each(unloadedInstruments, (item) => {
                promises.push(this.loadInstrument(item.instrument));
            });
            //ensure all instruments are preloaded
            Promise.all(promises).then(() => {
                resolve();
            });
        });
    }

    loadInstrument(instrument) {
        return new Promise((resolve) => {
            Soundfont.instrument(audioContext, instrument.soundFont, {
                soundfont: `/assets/soundfont/${instrument.soundFont}`,
            }).then((inst) => {
                let players = this.state.players;
                players[instrument.name] = inst;
                this.setState({players: players});
                this.props.notifyInstrumentLoadingState(LoadingState.SUCCESS);
                resolve();
            });
        });
    }

    playNote(midiNote) {
        return this.state.player.play(midiNote, audioContext.currentTime, {
            gain: GAIN,
        });
    }

    stopPlayback() {
        this.setState({
            playbackIsOn: false,
            playerIsOn: false,
        });
        this.disablePianoPlayer();
        this.enablePianoPlayer();
        this.props.stopAnimation();
    }

    playCountIn(tempo, signature) {
        const countInClicks = generateCountInClicks(parseInt(signature, 10));

        let offset = 0;
        countInClicks.forEach((item) => {
            setTimeout(() => {
                const duration = (tempo / 60) * 2;
                this.state.metronomePlayer.play(
                    item.note,
                    audioContext.currentTime,
                    {
                        gain: GAIN,
                        duration: 2 / duration,
                    }
                );
            }, offset);
            offset += item.duration * 500 * (60 / tempo);
        });
    }

    render() {
        return (
            <div>
                {this.state.playerIsOn ? (
                    <Menu.Item onClick={() => this.disablePianoPlayer()}>
                        <Icon size="large">
                            {" "}
                            <Image centered src={SoundOnIcon}/>
                        </Icon>
                        <span className="mobile-hide mtudeIconButton iconFont">
                            {" "}
                            On{" "}
                        </span>
                    </Menu.Item>
                ) : (
                    <Menu.Item onClick={this.enablePianoPlayer}>
                        <Icon size="large">
                            {" "}
                            <Image centered src={SoundOffIcon}/>
                        </Icon>
                        <span className="mobile-hide mtudeIconButton iconFont">
                            {" "}
                            Off
                        </span>
                    </Menu.Item>
                )}
            </div>
        );
    }
}

export default SoundPlayer;
