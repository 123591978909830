import React from "react";
import { Grid, GridColumn, Header, Image } from "semantic-ui-react";
import { Link } from "react-router";
import NotFoundImage from "../../assets/not_found.svg";

function NotFoundView() {
    return (
        <Grid verticalAlign="middle" style={{ height: "100%" }} centered>
            <GridColumn>
                <Header as="h2" style={{ display: "inline" }}>
                    <Image
                        className="animated infinite bounce"
                        size="medium"
                        src={NotFoundImage}
                    />
                    <Header.Subheader>
                        The page you are looking for does not exist. Go back to{" "}
                        <Link to="/">musitude.com</Link> to choose a new
                        direction
                    </Header.Subheader>
                </Header>
            </GridColumn>
        </Grid>
    );
}

export default NotFoundView;
