import React from "react";
import { IndexRoute, Route, Router } from "react-router";
import { hasSession } from "./services/sessionStorage";
import CoreLayout from "./views/CoreLayout";
import HomeView from "./views/HomeView";
import Player from "./views/Player/Player";
import NotFoundView from "./views/Error/NotFoundView";
import { Genres } from "./views/Browse/Genres";
import BrowseView from "./views/Browse/BrowseView";
import ListView from "./views/Browse/ListView";
import { DuetsView, EnsemblesView } from "./views/Browse/SongList";
import { withBus } from "react-suber";
import TermsAndConditionsView from "./views/Legal/TermsAndConditionsView";
import TeamMemberView from "./views/General/TeamMemberView";
import PrivacyPolicyView from "./views/Legal/PrivacyPolicyView";
import AndroidView from "./views/Landing/AndroidView";
import IOSView from "./views/Landing/IOSView";
import UnsupportedBrowserView from "./views/Landing/UnsupportedBrowserView";

import ReactGA from "react-ga";

ReactGA.initialize("UA-91307400-1");

const onHomePageEntry = (nextState, replace, done) => {
    if (hasSession()) {
        replace(`/profile`);
        done();
    } else {
        done();
    }
};

const fireTracking = () => {
    ReactGA.pageview(window.location.pathname);
};

const Routes = (props) => (
    <Router onUpdate={fireTracking} {...props}>
        <Route path="/" component={CoreLayout}>
            <IndexRoute
                onEnter={onHomePageEntry}
                component={withBus(HomeView)}
            />
            <Route path="player/:id" component={withBus(Player)} />

            <Route path="/browse" component={withBus(BrowseView)}>
                <IndexRoute component={Genres} />
                <Route path="list" component={ListView} />
                <Route path="duets" component={DuetsView} />
                <Route path="ensembles" component={EnsemblesView} />
            </Route>
        </Route>

        <Route path="/terms" component={TermsAndConditionsView} />
        <Route path="/privacy" component={PrivacyPolicyView} />
        <Route path="/team" component={TeamMemberView} />

        <Route path="/ios" component={IOSView} />
        <Route path="/android" component={AndroidView} />
        <Route path="/unsupported-browser" component={UnsupportedBrowserView} />

        <Route path="*" component={NotFoundView} />
    </Router>
);

export default Routes;
