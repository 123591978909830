import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import MusitudeFullLogo from '../../../assets/Musitude-Logo-RGB-Strap-R-compressor.jpg';

class Header extends Component {

    render() {
        return (
            <div className="bootstrap-styles olympus-styles" style={{ borderBottom: '2px solid #C90078' }}>
                <div
                    style={{
                        height: '90px',
                        backgroundColor: 'white',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-around'
                    }}
                >
                    <div />
                    <div>
                        <Image
                            style={{
                                width: '240px',
                                padding: '1em',
                                marginLeft: 100
                            }}
                            src={MusitudeFullLogo}
                        />
                    </div>
                    <div style={{width: '95px'}}>

                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
