import React, { Component } from 'react'
import { DEFAULTS } from '../config'

class ThreeQuarterRight extends Component {

    render() {
        return (
            <svg className="halfNoteDefault" viewBox="0 0 5 10">
                <polygon points="0.13 2.13 0 2 0 8 0.13 7.88 0.13 2.13"/>
                <path
                    d="M0,10A5,5,0,0,0,5,5,5,5,0,0,0,0,0H0V10ZM4.74,5A4.72,4.72,0,0,1,.24,9.48v-9A4.71,4.71,0,0,1,4.74,5Z"/>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSize} x="2"
                      y={DEFAULTS.textYPosition}>{this.props.note}</text>

            </svg>
        )
    }
}

export default ThreeQuarterRight
