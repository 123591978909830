import React from 'react'
import PropTypes from 'prop-types'

export const Card = props => (
    <div className="bootstrap-styles">
        <div className="olympus-styles">
            <div className="ui-block">
                <div className="ui-block-title">
                    <h5 className="title">{props.title}</h5>
                </div>
                <div>
                    {props.children}
                </div>
            </div>
        </div>
    </div>
)

Card.Props = {
    title: PropTypes.string,
    children: PropTypes.element
}
