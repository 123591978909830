import React, { Component } from 'react'
import { DEFAULTS } from '../config'

class ShortHalfRight extends Component {


    render() {
        return (
            <svg className="halfNoteDefault" viewBox="0 0 5 10">
                <path
                    d="M.24,9.48v-9C2.52.62,3.53,2.24,5,2.24V2C3.49,2,2.45,0,0,0V10c2.46,0,3.5-2,5-2V7.75C3.53,7.75,2.52,9.37.24,9.48Z"/>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSize} x="2.36"
                      y={DEFAULTS.textYPosition}>{this.props.note}</text>
            </svg>
        )
    }
}

export default ShortHalfRight
