import { GAIN } from './soundPlayerConfig'

/**
 * Generates the count in (metronome) clicks based on the time signature
 */

export const startClickNote = 76
export const clickNote = 66

const startClick = {
    duration: 2,
    gain: GAIN,
    note: startClickNote,
    isClick: true,
}

const click = {
    duration: 2,
    gain: GAIN,
    note: clickNote,
    isClick: true,
}

export const generateCountInClicks = (signature) => {
    const clicks = []
    for (let i = 0; i < 2; i++) {
        for (let j = signature; j > 0; j--) {
            if (j === signature) {
                clicks.push(startClick)
            } else {
                clicks.push(click)
            }
        }
    }
    return clicks
}
