export const Shape = {
    WHOLE: 'WHOLE',
    WHOLE_SHIFT: 'WHOLE_SHIFT',
    SUSTAIN: 'SUSTAIN',
    OPEN_RIGHT_SUSTAIN: 'OPEN_RIGHT_SUSTAIN',
    OPEN_LEFT_SUSTAIN: 'OPEN_LEFT_SUSTAIN',
    RIGHT: 'RIGHT',
    LEFT: 'LEFT',
    THREE_QUARTER_LEFT: 'THREE_QUARTER_LEFT',
    THREE_QUARTER_RIGHT: 'THREE_QUARTER_RIGHT',
    QUARTER_LEFT: 'QUARTER_LEFT',
    QUARTER_RIGHT: 'QUARTER_RIGHT',
    TRIPLET: 'TRIPLET',
}

export const COLORS = {
    inActive: '#7d7d7d',
}

export const DEFAULTS = {
    joinerWidth: 7.5,
    halfWidth: 25,
    cradleWidth: 55,
    fontSize: 6,
    textYPosition: 6.5,
    textXLeftPosition: 2.55,
    textXRightPosition: 2.75,
    textXMiddlePosition: 5,
    fontSizeTriplet: 4.7,
    fontSizeQuad: 10,
}

export const ShapesWithMargins = [
    Shape.WHOLE, Shape.RIGHT, Shape.THREE_QUARTER_RIGHT, Shape.QUARTER_RIGHT, Shape.SUSTAIN,
]

export const DisplayStates = {
    DEFAULT: 'play-default',
    SUCCESS: 'play-success',
    FAILED: 'play-failed',
    NEXT: 'play-next',
}

export const ShapeDuration = {
    FULL_SHAPE_DURATION: 2,
    SHORT_HALF_LEFT: 1,
    OPEN_SHORT_HALF_LEFT: 1,
    OPEN_RIGHT: 2,
    OPEN_LEFT: 2,
    SUSTAIN_CLOSE: 2,
    MIDDLE: 2,

}

export const Tempos = [40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220, 230, 240, 250]
