// we will trigger an event on the actual grid node after the exit animation completes
// to let the transitiongroup know that it can be removed from the DOM
// this is the only way to let react-transition-group delegate timing
// to the JavaScript animation, as far as I can tell
import anime from "animejs";

import each from "lodash/each";
const BASE_10 = 10;
const ANIMATION_DONE_EVENT = "animation::done";

const triggerAnimationDoneEvent = (node) =>
    node.dispatchEvent(new Event(ANIMATION_DONE_EVENT));

export const clearTrackAnimation = (animations, callback) => {
    if (animations) {
        each(animations, (trackAnimation) => {
            trackAnimation.restart();
            trackAnimation.pause();
        });
    }
    if (callback) {
        return callback();
    }
    return null;
};

export const initialiseAnimations = (
    animations,
    gridContainer,
    selectedTempo,
    variation
) => {
    let pieces = [];
    each(variation.cradles, (cradle) => {
        pieces = [...pieces, ...cradle.pieces];
    });
    return clearTrackAnimation(animations, () => {
        const signature = variation.signature;
        const tempoInSeconds = 2 / ((selectedTempo / 60) * 2);
        const notationWidth = 40.25; // 35(note) + 5.25  (joiner)
        const countIn = tempoInSeconds * signature * 2;

        const trackingLine = gridContainer.querySelectorAll(".trackingLine");
        //make tracking line span over to the next cradle so the swap between lines is smooth
        const LINE_POSITION_BUFFER = 1;
        const signatureWithBuffer =
            parseInt(signature, BASE_10) + LINE_POSITION_BUFFER;

        const notes = gridContainer.querySelectorAll(".dynamicNote");
        //track piece duration
        let pieceDuration = 0;
        animations = [];
        animations.push(
            anime({
                targets: notes,
                fill: [{ value: "#9700cc" }, { value: "#fbc006" }],
                duration: (el, i) => {
                    return tempoInSeconds * 400 * (pieces[i].duration / 2);
                },
                easing: "linear",
                delay: (el, i) => {
                    const delay =
                        tempoInSeconds * pieceDuration * 1000 + countIn * 1000;
                    pieceDuration += pieces[i].duration / 2;
                    return delay;
                },
                complete: (el, i) => {
                    triggerAnimationDoneEvent(gridContainer);
                },
            })
        );

        animations.push(
            anime({
                targets: trackingLine,
                //set the start position of the line animation
                translateX: [
                    {
                        value: [
                            LINE_POSITION_BUFFER,
                            notationWidth * signatureWithBuffer +
                                LINE_POSITION_BUFFER,
                        ],
                    },
                ],
                background: [
                    { value: "rgba(178, 16, 243, 0.6)" },
                    { value: "rgba(178, 16, 243, 0.6)" },
                    { value: "rgba(178, 16, 243, 0.6)" },
                    { value: "rgba(178, 16, 243, 0.6)" },
                    { value: "rgba(178, 16, 243, 0.6)" },
                    { value: "rgba(178, 16, 243, 0.6)" },
                    { value: "rgba(178, 16, 243, 0.6)" },
                    { value: "rgba(178, 16, 243, 0.5)" },
                    { value: "rgba(178, 16, 243, 0.4)" },
                    {
                        value: "rgba(178, 16, 243, 0.0)",
                        duration: 200,
                    },
                ],
                duration: tempoInSeconds * 1000 * signatureWithBuffer,
                easing: "linear",
                delay: function (el, i, l) {
                    return (
                        i * (tempoInSeconds * 1000 * signature) + countIn * 1000
                    );
                },
                complete: (el, i) => {
                    triggerAnimationDoneEvent(gridContainer);
                },
            })
        );

        return animations;
    });
};
