import React, { Component } from 'react'

class Middle extends Component {

    render() {
        return (
            <svg className="noteDefault" viewBox="0 0 10 10">
                <path d="M5,0C2.54,0,1.5,2,0,2v.25C1.5,2.25,2.54.5,5,.5s3.5,1.75,5,1.75V2C8.5,2,7.47,0,5,0Z"/>
                <path d="M0,7.75V8c1.5,0,2.54,2,5,2s3.5-2,5-2V7.75c-1.5,0-2.53,1.74-5,1.74S1.5,7.75,0,7.75Z"/>
            </svg>
        )
    }
}

export default Middle
