import React, { Component } from 'react'

class CallToAction extends Component {
    render() {
        return (
            <div className="bootstrap-styles">
                <div className="olympus-styles">
                    <section className="align-right pt160 pb80 section-move-bg call-to-action-animation scrollme">
                        <div className="container">
                            <div className="row">

                            </div>
                        </div>
                        <div className="content-bg-wrap">
                            <div className="content-bg bg-section1"/>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

export default CallToAction
