import React, { Component } from 'react'

class TextCard extends Component {
    render() {
        return (
            <div className="bootstrap-styles">
                <div className="olympus-styles">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="mb-0 pt-2 pb-2">
                                {this.props.header}
                            </h3>
                        </div>
                        <div className="pl-5 pr-5 pt-2">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TextCard
