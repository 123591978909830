import React from "react";
import PropTypes from "prop-types";
import { Image, Segment } from "semantic-ui-react";
import { browserHistory } from "react-router";

import Soundtrack from "../../assets/icons/categories/soundtrack.svg";
import Jazz from "../../assets/icons/categories/jazz.svg";
import Classical from "../../assets/icons/categories/classical.svg";
import Xmas from "../../assets/icons/categories/xmas.svg";
import Children from "../../assets/icons/categories/children.svg";
import Faith from "../../assets/icons/categories/faith.svg";
import NationalAnthem from "../../assets/icons/categories/national-anthem.svg";
import Rock from "../../assets/icons/categories/rock.svg";
import Blues from "../../assets/icons/categories/blues.svg";
import Pop from "../../assets/icons/categories/pop.svg";
import Folk from "../../assets/icons/categories/folk.svg";
import Rnb from "../../assets/icons/categories/rnb.svg";
import Vinyl from "../../assets/music/Vinyl.svg";
import capitalize from "lodash/capitalize";
import { gql, useQuery } from "@apollo/client";

const query = gql`
    query {
        categories {
            value
            description
        }
    }
`;

export const Genres = () => {
    const { loading, data } = useQuery(query);

    if (loading) {
        return null;
    }

    return (
        <div className="bootstrap-styles">
            <div className="row">
                {data.categories &&
                    data.categories.map((genre, i) => (
                        <div
                            key={`genre_card_${i}`}
                            className="col-md-2 col-sm-3 col-xs-4"
                        >
                            <Segment
                                className="genreCard"
                                textAlign="center"
                                onClick={() =>
                                    browserHistory.push(
                                        `/browse/list?genre=${genre.value}`
                                    )
                                }
                            >
                                <Image
                                    size="small"
                                    centered
                                    src={getGenreImage(genre.value)}
                                />
                            </Segment>
                            <div className="genreTitle">
                                {formatGenre(genre.value)}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

Genres.Props = {
    genres: PropTypes.arrayOf(PropTypes.string),
};

export const formatGenre = (genre) =>
    genre
        .split("_")
        .map((e) => capitalize(e))
        .join(" ");

const getGenreImage = (genre) => {
    let genreImage;
    switch (genre) {
        case "SOUNDTRACK":
            genreImage = Soundtrack;
            break;
        case "JAZZ":
            genreImage = Jazz;
            break;
        case "CLASSICAL":
            genreImage = Classical;
            break;
        case "XMAS":
            genreImage = Xmas;
            break;
        case "CHILDREN":
            genreImage = Children;
            break;
        case "FAITH":
            genreImage = Faith;
            break;
        case "NATIONAL_ANTHEM":
            genreImage = NationalAnthem;
            break;
        case "ROCK":
            genreImage = Rock;
            break;
        case "BLUES":
            genreImage = Blues;
            break;
        case "POP":
            genreImage = Pop;
            break;
        case "FOLK":
            genreImage = Folk;
            break;
        case "RNB":
            genreImage = Rnb;
            break;
        default:
            genreImage = Vinyl;
    }
    return genreImage;
};
