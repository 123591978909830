import React, { Component } from 'react'
import { DEFAULTS } from '../config'

class BlockHalfLeft extends Component {
    render() {
        return (
            <svg className="halfNoteDefault" viewBox="0 0 5 10">
                <path d="M5,0A5,5,0,0,0,5,10H5V0ZM.49,5A4.49,4.49,0,0,1,4.74.51v9A4.5,4.5,0,0,1,.49,5Z"/>
                <text textAnchor="middle" fontSize={DEFAULTS.fontSize} x={DEFAULTS.textXLeftPosition}
                      y={DEFAULTS.textYPosition}>{this.props.note}</text>
            </svg>
        )
    }
}

export default BlockHalfLeft
