import React from "react";
import LandingPage from "../components/olympus/home/LandingPage";
import Header from "../components/olympus/home/Header";
import Footer from "../components/olympus/components/Footer";
import CallToAction from "../components/olympus/home/CallToAction";
import DemoPlayer from "../views/DemoPlayer/DemoPlayer";
import CookieNotifier from "../components/home/CookieNotifier";
import ToastrContainer from "react-toastr-basic";
import Navigation from "../components/olympus/components/Navigation";

function HomeView(props) {
    return (
        <div>
            <ToastrContainer />
            <Navigation bus={props.bus} />

            <CookieNotifier />
            <Header />
            <DemoPlayer bus={props.bus} />
            <LandingPage bus={props.bus} />
            <CallToAction />
            <Footer />
        </div>
    );
}

export default HomeView;
