import * as React from "react";
import { useContext, useReducer } from "react";
import { initialRendererState } from "./components/Renderer/renderer.state";

const Store = React.createContext(null);

const initialState = {
    renderer: initialRendererState,
};

const reducers = {};

export const StoreProvider = ({ children }) => {
    const store = createStore(reducers);
    return <Store.Provider value={store}>{children}</Store.Provider>;
};

function createStore(reducers) {
    const [state, dispatch] = useReducer(
        combineReducers(reducers),
        initialState
    );
    return { state, dispatch };
}

export function useStore() {
    return useContext(Store);
}

function combineReducers(reducers) {
    return (state, action) => {
        return Object.keys(reducers).reduce((nextState, key) => {
            nextState[key] = reducers[key](state[key], action);
            return nextState;
        }, initialState);
    };
}
