import React, { Component } from 'react'
import ShortHalfLeft from './ShortHalfLeft'
import Joiner from './Joiner'
import OpenLeft from './OpenLeft'
import Middle from './Middle'
import { ShapeDuration } from '../config'
import ShortHalfRight from './ShortHalfRight'

class OpenRightSustain extends Component {

    static defaultProps = {
        oddStartPosition: false,
    }

    buildStart(oddStartPosition) {
        if (oddStartPosition) {
            return <ShortHalfRight note={this.props.note}/>
        } else {
            return <OpenLeft note={this.props.note}/>
        }
    }

    /**
     * Build the middle for sustain shape.
     * The middle is calculated as the duration minus the beats rendered by start and end blocks.
     * @param duration
     * @returns {Array}
     */
    buildSustainMiddle(duration, oddStartPosition) {
        const BEATS_PER_MIDDLE = ShapeDuration.MIDDLE
        let oddDuration = this.isOdd(duration)
        //calculate how many beats (middle notes) should be rendered
        let beatsRemaining = duration

        if (oddStartPosition) {
            //remove beat for OPEN_SHORT_HALF_LEFT shape
            beatsRemaining -= ShapeDuration.OPEN_SHORT_HALF_LEFT
        } else {
            //remove 2 beats for OPEN_LEFT shape
            beatsRemaining -= ShapeDuration.OPEN_LEFT
        }
        //remove beats for closing shape
        if ((oddDuration && oddStartPosition) || (!oddDuration && !oddStartPosition)) {
            beatsRemaining -= ShapeDuration.MIDDLE
        } else {
            beatsRemaining -= ShapeDuration.SHORT_HALF_LEFT
        }
        let middle = []
        let noOfMiddleNotes = Math.round(beatsRemaining / BEATS_PER_MIDDLE)

        for (let i = 0; i < noOfMiddleNotes; i++) {
            middle.push(<Joiner key={'j-' + i.toString()}/>)
            middle.push(<Middle key={'m-' + i.toString()}/>)
        }

        return middle
    }

    buildSustainEnd(duration, oddStartPosition, cradlePosition, signature) {
        let oddDuration = this.isOdd(duration)
        let end = []
        const FULL_SHAPE_DURATION = ShapeDuration.FULL_SHAPE_DURATION
        if (duration > FULL_SHAPE_DURATION) {
            if ((oddDuration && oddStartPosition) || (!oddDuration && !oddStartPosition)) {
                end.push(<Joiner key='j-end1'/>)
                end.push(<Middle key='ohr-end'/>)
                end.push(<Joiner key='j-end2'/>)
            } else {
                end.push(<Joiner key='j-end1'/>)
                end.push(<ShortHalfLeft key='oshl-ends'/>)
            }
        }

        //if the shape starts at the end of a cradle, add another joiner for the gap
        if (cradlePosition >= ((signature * FULL_SHAPE_DURATION) - FULL_SHAPE_DURATION)) {
            end.push(<Joiner key='j-end1'/>)
        }

        return end
    }

    isOdd(num) {
        return num % 2
    }

    render() {
        let duration = this.props.duration
        const oddStartPosition = this.props.oddStartPosition
        const cradlePosition = this.props.cradlePosition
        const signature = this.props.signature
        return (
            <span>
                {this.buildStart(oddStartPosition)}
                {this.buildSustainMiddle(duration, oddStartPosition)}
                {this.buildSustainEnd(duration, oddStartPosition, cradlePosition, signature)}
            </span>
        )
    }
}

export default OpenRightSustain
