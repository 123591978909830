import React, { Component } from "react";
import {
    Dropdown,
    Popup,
    Icon,
    Image,
    Menu,
    Header,
    Grid,
} from "semantic-ui-react";
import isUndefined from "lodash/isUndefined";
import isEmpty from "lodash/isEmpty";
import each from "lodash/each";
import sortBy from "lodash/sortBy";
import SoundPlayer from "../player/SoundPlayer";
import AutoplayerButton from "./../notation/AutoplayerButton";
import RangeIcon from "../../assets/icons/player/range.svg";
import SpeedIcon from "../../assets/icons/player/speed.svg";
import VariationIcon from "../../assets/icons/player/sheet.svg";
import Banjo from "../../assets/icons/instruments/banjo.svg";
import Bassoon from "../../assets/icons/instruments/Bassoon.svg";
import Clarinet from "../../assets/icons/instruments/Clarinet.svg";
import Electricguitar from "../../assets/icons/instruments/Electricguitar.svg";
import Flute from "../../assets/icons/instruments/Flute.svg";
import Glockenspiel from "../../assets/icons/instruments/glockenspiel.svg";
import Guitar from "../../assets/icons/instruments/guitar.svg";
import Harp from "../../assets/icons/instruments/harp.svg";
import Harpsichord from "../../assets/icons/instruments/Harpsichord.svg";
import Oboe from "../../assets/icons/instruments/Oboe.svg";
import Panflute from "../../assets/icons/instruments/PanFlute.svg";
import Piano from "../../assets/icons/instruments/piano.svg";
import Pizzicato from "../../assets/icons/instruments/Pizzicato.svg";
import TinkleBell from "../../assets/icons/instruments/TinkleBell.svg";
import Violin from "../../assets/icons/instruments/Violin.svg";
import PlayIcon from "../../assets/icons/player/play.svg";
import StopIcon from "../../assets/icons/player/stop.svg";

//instrument displayName is the key
const instrumentImages = {
    Banjo: Banjo,
    Bassoon: Bassoon,
    Clarinet: Clarinet,
    "Electric Guitar": Electricguitar,
    Flute: Flute,
    Glockenspiel: Glockenspiel,
    Guitar: Guitar,
    Harp: Harp,
    Harpsichord: Harpsichord,
    Oboe: Oboe,
    "Pan Flute": Panflute,
    Piano: Piano,
    "Tinkle Bell": TinkleBell,
    Violin: Violin,
    Pizzicato: Pizzicato,
};

class NotationHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            instruments: props.instruments,
            notation: props.notation,
            variation: props.variation,
            selectedTempo: props.selectedTempo,
            selectedInstrumentIndex: undefined,
            trackingPopoverIsOpen: false,
            playTrackerActive: true,
        };

        this.showInstrumentsWidget.bind(this);
        this.showVariationWidget.bind(this);
        this.showRangeSelectionWidget.bind(this);
        this.playTrackerChanged = this.playTrackerChanged.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            instruments: this.props.instruments,
            notation: props.notation,
            variation: props.variation,
            selectedTempo: props.selectedTempo,
        });
    }

    instrumentContentHeader(name) {
        return <Header as="h5" image={instrumentImages[name]} content={name} />;
    }

    playTrackerChanged(value) {
        this.setState({
            playTrackerActive: !value,
        });
    }

    showInstrumentsWidget(defaultInstrument) {
        let options = [];
        each(this.state.instruments, (item, index) => {
            if (!isUndefined(instrumentImages[item.displayName])) {
                options.push({
                    value: index,
                    content: this.instrumentContentHeader(item.displayName),
                });
            }
        });

        const instrumentTrigger = (
            <Menu.Item>
                <Icon size="large">
                    {" "}
                    <Image
                        centered
                        src={instrumentImages[defaultInstrument.displayName]}
                    />
                </Icon>
                <span className="mobile-hide mtudeIconButton iconFont">
                    {" "}
                    {defaultInstrument.displayName}
                </span>
            </Menu.Item>
        );

        return (
            <Dropdown
                className="instrumentDropdown"
                trigger={instrumentTrigger}
                onChange={this.instrumentChanged}
                pointing="top right"
                icon={null}
                options={options}
                value={defaultInstrument.index}
            />
        );
    }

    showVariationWidget() {
        let options = [];
        each(this.props.notation.variations, function (item, index) {
            options.push({ text: item.type.displayValue, value: index });
        });
        const variationTrigger = (
            <Menu.Item>
                <Icon size="large">
                    {" "}
                    <Image centered src={VariationIcon} />
                </Icon>
                <span className="mobile-hide mtudeIconButton iconFont">
                    {" "}
                    {this.state.variation.type.displayValue}{" "}
                </span>
            </Menu.Item>
        );

        return (
            <Dropdown
                trigger={variationTrigger}
                onChange={this.variationChanged.bind(this)}
                pointing="top right"
                icon={null}
                options={options}
                defaultValue={options[0].value}
            />
        );
    }

    showRangeSelectionWidget(defaultRange) {
        const RANGE_LABEL = "Range ";
        let options = [];
        for (let i = 5; i >= 1; i--) {
            options.push({ text: RANGE_LABEL + i, value: i });
        }

        const rangeTrigger = (
            <Menu.Item>
                <Icon size="large">
                    {" "}
                    <Image centered src={RangeIcon} />
                </Icon>
                <span className="mobile-hide mtudeIconButton iconFont">
                    {RANGE_LABEL}
                </span>
            </Menu.Item>
        );

        return (
            <Dropdown
                trigger={rangeTrigger}
                onChange={this.updateSoundPlayerRange.bind(this)}
                pointing="top left"
                icon={null}
                options={options}
                value={this.state.selectedRange || defaultRange}
            />
        );
    }

    showTempoWidget(defaultTempo) {
        const options = [
            { text: "Very Slow", value: Math.round(defaultTempo * 0.5) },
            { text: "Slow", value: Math.round(defaultTempo * 0.8) },
            { text: "Normal", value: Math.round(defaultTempo) },
            { text: "Fast", value: Math.round(defaultTempo * 1.5) },
            { text: "Very Fast", value: Math.round(defaultTempo * 2) },
        ];

        const tempoTrigger = (
            <Menu.Item>
                <Icon size="large">
                    <Image centered src={SpeedIcon} />
                </Icon>
                <span className="mobile-hide mtudeIconButton iconFont">
                    Speed
                </span>
            </Menu.Item>
        );

        return (
            <Dropdown
                onChange={this.tempoChanged.bind(this)}
                trigger={tempoTrigger}
                pointing="top left"
                icon={null}
                options={sortBy(options, "value").reverse()}
                value={this.state.selectedTempo || defaultTempo}
            />
        );
    }

    instrumentChanged = (event, data) => {
        this.setState({ selectedInstrumentIndex: data.value });
    };

    variationChanged(event, data) {
        //tell DynamicNotationContainer that variation has changed
        this.props.stopAnimation();
        this.props.variationChanged(event, data);
        this.setState({ selectedInstrumentIndex: undefined });
    }

    tempoChanged(event, data) {
        this.props.tempoChanged(event, data);
    }

    trackerChanged(val) {
        const { variation, selectedTempo } = this.state;
        if (val === "Play") {
            this.soundPlayer.playCountIn(
                selectedTempo || variation.tempo,
                variation.signature
            );
            this.props.playAnimation(
                selectedTempo || variation.tempo,
                variation
            );
        } else if (val === "Stop") {
            this.props.stopAnimation();
        } else if (val === "Pause") {
            this.props.pauseAnimation();
        }
    }

    updateSoundPlayerRange(event, data) {
        this.soundPlayer.loadKeyMappings(data.value);
        this.setState({
            selectedRange: data.value,
        });
    }

    updateVariationNotes(variation) {
        this.setState({ variation: variation });
    }
    handleOpen = () => {
        this.setState({ trackingPopoverIsOpen: true });
    };

    handleClose = () => {
        this.setState({ trackingPopoverIsOpen: false });
    };
    showTrackingMenu() {
        const tempoTrigger = (
            <Menu.Item>
                <Icon
                    className="info"
                    size="large"
                    style={{ color: "#b874cc" }}
                />
                <span className="mobile-hide mtudeIconButton iconFont">
                    Tracking
                </span>
            </Menu.Item>
        );

        return (
            <Popup
                style={{ height: 70 }}
                trigger={tempoTrigger}
                open={this.state.trackingPopoverIsOpen}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
                on="click"
                position="bottom left"
            >
                <Grid.Row>
                    <Grid.Column>
                        <span
                            className="mtudeIconButton"
                            onClick={() => {
                                this.trackerChanged("Play");
                                this.handleClose();
                            }}
                        >
                            <Icon size="large">
                                {" "}
                                <Image centered src={PlayIcon} />{" "}
                            </Icon>{" "}
                            Start
                        </span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <span
                            className="mtudeIconButton"
                            onClick={() => {
                                this.trackerChanged("Stop");
                                this.handleClose();
                            }}
                        >
                            <Icon size="large">
                                {" "}
                                <Image centered src={StopIcon} />{" "}
                            </Icon>{" "}
                            Stop
                        </span>
                    </Grid.Column>
                </Grid.Row>
            </Popup>
        );
    }

    render() {
        const {
            notation,
            variation,
            instruments,
            selectedTempo,
            selectedInstrumentIndex,
            playTrackerActive,
        } = this.state;

        // use the selected instrument, or the default one if the user has not selected an
        // instrument yet
        const instrument =
            instruments[
                selectedInstrumentIndex !== undefined
                    ? selectedInstrumentIndex
                    : variation.instrument.index
            ];
        if (isEmpty(notation.variations)) {
            return <div />;
        }

        const demoplayer = this.props.demoplayer;

        return (
            <Menu
                secondary
                fluid
                icon="labeled"
                borderless
                className="playerMenu noMarginBorder"
            >
                <SoundPlayer
                    bus={this.props.bus}
                    notifyInstrumentLoadingState={
                        this.props.notifyInstrumentLoadingState
                    }
                    instrument={instrument}
                    variations={notation.variations}
                    variation={variation}
                    notationId={notation.id}
                    onRef={(ref) => (this.soundPlayer = ref)}
                    instrumentLoading={this.onInstrumentLoading}
                    playAnimation={this.props.playAnimation.bind(this)}
                    demoplayer={demoplayer}
                    stopAnimation={this.props.stopAnimation.bind(this)}
                    tempo={selectedTempo}
                    trackerIsOn={this.state.playTrackerActive}
                />
                {this.showTempoWidget(variation.tempo)}
                {this.showRangeSelectionWidget(variation.range)}
                {
                    <AutoplayerButton
                        bus={this.props.bus}
                        soundplayer={this.soundPlayer}
                        variation={variation}
                        variations={notation.variations}
                        playTrackerChanged={this.playTrackerChanged}
                        playTrackerActive={playTrackerActive}
                    />
                }
                {this.showTrackingMenu(variation.tempo)}

                <Menu.Menu position="right">
                    {notation.variations.length > 1
                        ? this.showVariationWidget()
                        : ""}
                    {this.showInstrumentsWidget(instrument)}
                </Menu.Menu>
            </Menu>
        );
    }
}

export default NotationHeader;
