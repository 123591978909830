import React, { Component } from 'react'

class Empty extends Component {


    render() {
        return (
            <svg  className="noteDefault restNote" viewBox="0 0 10 10">
                <path d="M8.57,2.07l.34-.2A5,5,0,0,0,5.76.06v.5A5,5,0,0,1,8.57,2.07Z"/>
                <path d="M4.26.56V.06A5,5,0,0,0,1.1,1.87l.44.26A4.44,4.44,0,0,1,4.26.56Z"/>
                <path
                    d="M9.76,5A4.24,4.24,0,0,1,9.4,6.66l.26.15A5,5,0,0,0,10,5a5,5,0,0,0-.35-1.82l-.26.15A4.28,4.28,0,0,1,9.76,5Z"/>
                <path d="M1.54,7.86l-.44.25A5,5,0,0,0,4.26,9.93v-.5A4.49,4.49,0,0,1,1.54,7.86Z"/>
                <path
                    d="M.51,5A4.45,4.45,0,0,1,.79,3.43L.35,3.17A5,5,0,0,0,0,5,5,5,0,0,0,.35,6.81l.44-.25A4.51,4.51,0,0,1,.51,5Z"/>
                <path d="M5.76,9.43v.5A5,5,0,0,0,8.91,8.11l-.34-.19A5,5,0,0,1,5.76,9.43Z"/>
            </svg>
        )
    }
}

export default Empty
