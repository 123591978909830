import React from 'react'
import { NotationRenderer } from './Renderer.component'
import { useStore } from '../../store'

let slider = React.createRef();

const mapStoreToProps = (state, dispatch, props) => {

    return {
        height: props.height,
        width: props.width,
        variation: props.variation,
    }
}

export const RendererContainer = props => {

    const { state, dispatch } = useStore()

    return <NotationRenderer ref={slider} {...mapStoreToProps(state, dispatch, props)}/>
}

const upListener = (event) => {
    if (event.code === 'ArrowRight' || event.code === 'Enter') {
        event.preventDefault()
        slider.current && slider.current.slickNext()
    }
    if (event.code === 'ArrowLeft' || event.code === 'Backspace') {
        event.preventDefault()
        slider.current && slider.current.slickPrev()
    }
}
window.addEventListener('keyup', upListener)
