import React from "react";
import Header from "../../components/olympus/components/Header";
import Footer from "../../components/olympus/components/Footer";
import TeamSection from "../../components/olympus/components/TeamSection";

function TermsAndConditions() {
    return (
        <div className="bootstrap-styles">
            <div className="olympus-styles">
                <Header title="The Musitude Team" />
                <section className="mb60 mt-5">
                    <div className="container body-bg-white">
                        <TeamSection />
                    </div>
                </section>

                <Footer />
            </div>
        </div>
    );
}

export default TermsAndConditions;
